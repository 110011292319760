import React, { useEffect, useState } from 'react'
import './SportMarketStandard.scss'
import OddButton from '../OddButton/oddbutton'
import BetPlacing from '../BetPlacing/BetPlacing'
import { connectSportMarketStandard } from './SportMarketStandard.connect'
import timerIcon from '../../assets/images/timer.svg'
import { useHistory } from 'react-router'
import infoIcon from '../../assets/images/info_icon.svg'

import { useDispatch, useSelector } from 'react-redux'
import {
  addfavoritesSportMarkets,
  clearfavoritesSportMarkets,
  addFavoritesAll,
  clearFavoritesAll,
  clearfavoritesItem,
  addfavoritesItem,
} from '../../store/favorites/favorites.slice'

import StarIcon from '../../assets/images/sideBarStar.svg'
import StarIconFilled from '../../assets/images/sideBarStarFilled.svg'
import fasterBetAcceptanceIcon from '../../assets/images/fasterBetAcceptanceIcon.svg'
import noCommissionIcon from '../../assets/images/noCommissionIcon.svg'
import SportMarketStandardPopUp from './SportMarketStandardPopUp/SportMarketStandardPopUp'
import { getCurrencyFormat } from '../../helpers/getCurrencyFormat.helper'
import { getPlacingText } from '../../helpers/betPlacing.helper'
import { toastr } from 'react-redux-toastr'
import { requestBetPlacingStart } from '../../store/betPlacing/betPlacing.thunks'
import {
  setSportLadderId,
  setSportLadderShow,
  setSportLadderTitle,
} from '../../store/sportEvent/sportEvent.slice'
import { requestSportLadder } from '../../store/sportEvent/sportEvent.thunks'
import { IonIcon } from '@ionic/react'

const SportMarketStandard = ({
  data,
  betPlacing,
  exposure,
  actions,
  isRacing = false,
  showHeader = true,
  showJockeyName = false,
  sortRunners = false,
  showWinner = false,
  full,
  elementsParams,
  sportLimits,
  selectedRaces = [],
  setSelectedRaces,
}) => {
  const dispatch = useDispatch()
  const [besSettings, setBesSettings] = useState(null)
  const [cashOutData, setCashData] = useState(0)
  const isFancy = data?.mtype == 'INNINGS_RUNS' && data?.btype == 'LINE'

  const width =
    elementsParams && elementsParams?.clientWidth
      ? elementsParams?.clientWidth
      : window.innerWidth
  let sportid =
    localStorage.getItem('page') && localStorage.getItem('page')?.split('/')[2]
  useEffect(() => {
    if (isFancy && width >= 1024) {
      dispatch(
        requestBetPlacingStart(data?.id, data?.runners[0]?.id, true)
      ).then((res) => setBesSettings(res?.data?.result))
    }
  }, [data, isFancy, width])

  const calculateExposure = (runnerId) => {
    let number = 0,
      calced
    // const isFancy = data.mtype == "INNINGS_RUNS" && data.btype == "LINE"
    const pnlId = data.market_id
    if (exposure && exposure && exposure[pnlId]) {
      let findNumber = exposure[pnlId]?.filter(
        (item) => item.selection_id == runnerId
      )
      number = findNumber[0].user_pl
    }
    if (betPlacing.market === data.market_id) {
      if (betPlacing.runner === runnerId) {
        if (betPlacing.isBack) {
          // const profit = isFancy ? (betPlacing.stake * betPlacing.priceActual / 100).toFixed(2) : (betPlacing.stake * betPlacing.priceActual - betPlacing.stake).toFixed(2)
          const profit = isFancy
            ? betPlacing.stake
            : (
                betPlacing.stake * betPlacing.priceActual -
                betPlacing.stake
              ).toFixed(2)
          calced = (isFancy ? 0 : number) + Number(profit) * (isFancy ? -1 : 1)
        } else {
          const profit = isFancy
            ? betPlacing.stake * (betPlacing.priceActual / 100)
            : (
                betPlacing.stake * betPlacing.priceActual -
                betPlacing.stake
              ).toFixed(2)
          calced = (isFancy ? 0 : number) + Number(profit) * -1
        }
      } else {
        calced = betPlacing.isBack
          ? number - Number(betPlacing.stake)
          : number - Number(betPlacing.stake) * -1
        // calced = number - Number(betPlacing.stake)
      }
    }
    if (number) return number
  }

  const cashOut = (market) => {
    let greenAmount = 0
    let redAmount = 0
    let currentlaygrrenodds = 0
    let runners
    let runners_point = []
    for (var i = 0; i < market?.runners?.length; i++) {
      if (
        calculateExposure(market.runners[0].selectionId) > 0 &&
        calculateExposure(market.runners[1].selectionId) > 0
      ) {
        if (
          calculateExposure(market.runners[0].selectionId) >
          calculateExposure(market.runners[1].selectionId)
        ) {
          greenAmount = calculateExposure(market.runners[0].selectionId)
          currentlaygrrenodds = market.runners[0]?.ex?.availableToLay[0]?.price
          runners = market.runners[0]
          runners_point.push(greenAmount)
          redAmount = calculateExposure(market.runners[1].selectionId)
          runners_point.push(redAmount)
        } else {
          greenAmount = calculateExposure(market.runners[1].selectionId)
          currentlaygrrenodds = market.runners[1]?.ex?.availableToLay[0]?.price
          runners = market.runners[1]
          runners_point.push(greenAmount)
          redAmount = calculateExposure(market.runners[0].selectionId)
          runners_point.push(redAmount)
        }
      } else {
        if (calculateExposure(market.runners[i].selectionId) >= 0) {
          greenAmount = calculateExposure(market.runners[i].selectionId)
          currentlaygrrenodds = market.runners[i]?.ex?.availableToLay[0]?.price
          runners = market.runners[i]
          runners_point.push(greenAmount)
        } else {
          redAmount = calculateExposure(market.runners[i].selectionId)
          runners_point.push(redAmount)
        }
      }
    }

    let layStake = (greenAmount - redAmount) / currentlaygrrenodds

    if (!runners) {
      if (Math.trunc(runners_point[0]) == Math.trunc(runners_point[1])) {
        return
      }
      if (runners_point[0] > runners_point[1]) {
        runners = market.runners[0]
        greenAmount = calculateExposure(market.runners[0].selectionId)
        currentlaygrrenodds = market.runners[0]?.ex?.availableToLay[0]?.price
        redAmount = calculateExposure(market.runners[1].selectionId)
      } else {
        runners = market.runners[1]
        greenAmount = calculateExposure(market.runners[1].selectionId)
        currentlaygrrenodds = market.runners[1]?.ex?.availableToLay[1]?.price
        redAmount = calculateExposure(market.runners[0].selectionId)
      }
    }

    if (layStake === Infinity) {
      layStake = (greenAmount - redAmount) / currentlaygrrenodds
    }

    actions.setMarket(market.market_id)
    actions.setRunner(runners.selectionId)
    actions.setRunnerName(runners.name)
    actions.setEventName(market && market.market_name)
    actions.setIsBack(false)
    actions.setPrice(runners.ex.availableToLay[0].price)
    actions.setPriceActual(runners.ex.availableToLay[0].price)
    actions.setLine(isFancy)
    actions.setStake(Math.floor(layStake))
  }

  const sendCahsOutRequest = (marketId, delay) => {
    cashOut(marketId)
    actions.setDelay(delay)
    actions.setCashOutId(marketId.marketId)
    actions.setIsCashOut(true)
    actions.setBetPlacingPopoverStage(false)
    actions.setShowBetPlacingPopover(true)
    // actions.requestCashOut(marketId).then((res) => {
    //   actions.setShowBetPlacingPopover(false);
    //   const sizeMatched = res.data.result?.result?.placed?.[0].sizeMatched;
    //   if (sizeMatched > 0) {
    //     toastr.success("", "Cash-out successful");
    //   }
    //   // cancel()
    // }).catch((e) => {
    //   // cancel()
    //   actions.setShowBetPlacingPopover(false);
    //   if (e[0] && e[0][0] && e[0][0].description) toastr.error("", e[0][0].description);
    //   else toastr.error("", "Something went wrong");
    // });
  }

  const compareNumbers = (num0, num1) => {
    if (num0 > num1) {
      return 1
    } else if (num0 < num1) {
      return 0
    }
  }

  const cashOutShown = async (market) => {
    let greenAmount = 0
    let redAmount = 0
    let currentlaygrrenodds = 0
    let runners
    let runners_point = []
    for (var i = 0; i < market?.runners?.length; i++) {
      if (
        calculateExposure(market?.runners[0]?.selectionId) > 0 &&
        calculateExposure(market?.runners[1]?.selectionId) > 0
      ) {
        if (
          calculateExposure(market?.runners[0]?.selectionId) >
          calculateExposure(market?.runners[1]?.selectionId)
        ) {
          greenAmount = calculateExposure(market?.runners[0]?.selectionId)
          currentlaygrrenodds = market?.runners[0]?.ex?.availableToLay[0]?.price
          runners = market?.runners[0]
          runners_point.push(greenAmount)
          redAmount = calculateExposure(market?.runners[1]?.selectionId)
          runners_point.push(redAmount)
        } else {
          greenAmount = calculateExposure(market?.runners[1]?.selectionId)
          currentlaygrrenodds = market?.runners[1]?.ex?.availableToLay[0]?.price
          runners = market?.runners[1]
          runners_point.push(greenAmount)
          redAmount = calculateExposure(market?.runners[0]?.selectionId)
          runners_point.push(redAmount)
        }
      } else {
        if (calculateExposure(market?.runners[i].selectionId) >= 0) {
          greenAmount = calculateExposure(market?.runners[i]?.selectionId)
          currentlaygrrenodds = market?.runners[i]?.ex?.availableToLay[0]?.price
          runners = market?.runners[i]
          runners_point.push(greenAmount)
        } else {
          redAmount = calculateExposure(market?.runners[i]?.selectionId)
          runners_point.push(redAmount)
        }
      }
    }

    let layStake = (greenAmount - redAmount) / currentlaygrrenodds

    if (!runners) {
      if (Math?.trunc(runners_point[0]) == Math?.trunc(runners_point[1])) {
        return
      }
      if (runners_point[0] > runners_point[1]) {
        runners = market?.runners[0]
        greenAmount = calculateExposure(market?.runners[0].selectionId)
        currentlaygrrenodds = market?.runners[0]?.ex?.availableToLay[0]?.price
        redAmount = calculateExposure(market?.runners[1].selectionId)
      } else {
        runners = market?.runners[1]
        greenAmount = calculateExposure(market?.runners[1].selectionId)
        currentlaygrrenodds = market?.runners[1]?.ex?.availableToLay[1]?.price
        redAmount = calculateExposure(market?.runners[0].selectionId)
      }
    }

    if (layStake === Infinity) {
      layStake = (greenAmount - redAmount) / currentlaygrrenodds
    }

    const betData = {
      market: market?.market_id,
      runner: runners?.selectionId,
      isBack: false,
      stake: Math.floor(layStake),
      priceActual: runners?.ex?.availableToLay[0]?.price,
    }
    return betData
  }

  const cashoutExposure = async (runnerId, isIndex) => {
    let betPlacingData = await cashOutShown(data)

    let number = 0,
      calced
    const pnlId = data.market_id
    if (exposure && exposure && exposure[pnlId]) {
      let findNumber = exposure[pnlId]?.filter(
        (item) => item.selection_id == runnerId
      )
      number = findNumber[0].user_pl
    }
    if (betPlacingData.market === data.market_id) {
      if (betPlacingData.runner === runnerId) {
        if (betPlacingData.isBack) {
          const profit = isFancy
            ? betPlacingData.stake
            : (
                betPlacingData.stake * betPlacingData.priceActual -
                betPlacingData.stake
              ).toFixed(2)
          calced = (isFancy ? 0 : number) + Number(profit) * (isFancy ? -1 : 1)
        } else {
          const profit = isFancy
            ? betPlacingData.stake * (betPlacingData.priceActual / 100)
            : (
                betPlacingData.stake * betPlacingData.priceActual -
                betPlacingData.stake
              ).toFixed(2)
          calced = (isFancy ? 0 : number) + Number(profit) * -1
        }
      } else {
        calced = betPlacingData.isBack
          ? number - Number(betPlacingData.stake)
          : number - Number(betPlacingData.stake) * -1
      }
    }
    if (calced && isIndex) return calced
    if (number) return number
  }

  useEffect(() => {
    const getCashoutData = async () => {
      let cashoutValue = await calculateprofiltlossCashout(data)
      setCashData(Math.floor(cashoutValue))
    }
    data?.runners?.length === 2 &&
      data?.market_type === 'MATCH_ODDS' &&
      getCashoutData()
  }, [data])

  const calculateprofiltlossCashout = async (market) => {
    if (market?.runners?.length !== 2) {
      return 0
    }
    let firstRunner = await cashoutExposure(market?.runners[0]?.selectionId)
    let secondRunner = await cashoutExposure(market?.runners[1]?.selectionId)
    if (Math.floor(firstRunner) === Math.floor(secondRunner)) {
      return 0
    }

    let findindex = compareNumbers(firstRunner, secondRunner)
    let indexRunner = await cashoutExposure(
      market?.runners[findindex]?.selectionId,
      true
    )
    if (indexRunner) {
      return indexRunner
    } else {
      return 0
    }
  }

  const renderBetPlacing = (runner) => {
    const status = data.GameStatus || data.display_message || data.status
    const statusLabel = data.statusLabel
    // if( betPlacing.market === data.market_id) {
    //   console.log("@@@@betPlacing ", betPlacing, "check here","data", data)
    //   console.log("@@@ betPlacing.market", betPlacing.market, "data.market_id", data.market_id, "betPlacing.runner", betPlacing.runner, "runner.id", runner.id)
    //   console.log("@@@betPlacing.market === data.id", betPlacing.market === data.market_id, "betPlacing.runner === runner.id", betPlacing.runner === runner.id)
    //   console.log("status", status, "!status.localeCompare(OPEN)", !status?.localeCompare("OPEN"))
    //   console.log("(!statusLabel || betPlacing.btype != casino)", (!statusLabel || betPlacing.btype != "casino"))
    //   console.log("width < 1024", width < 1024)
    //   console.log("ALL CHECK", betPlacing.market === data.market_id &&
    //   betPlacing.runner === runner.id &&
    //   status &&
    //   !status.localeCompare("OPEN") &&
    //   (!statusLabel || betPlacing.btype != "casino") &&
    //   width < 1024)
    // }
    if (
      betPlacing.market === data.market_id &&
      betPlacing.runner === runner.id &&
      status &&
      !status.localeCompare('OPEN') &&
      (!statusLabel || betPlacing.btype != 'casino') &&
      width < 1024
    ) {
      return <BetPlacing />
    }
    return null
  }

  const [clicked, setClicked] = useState(null)
  const [winner, setWinner] = useState()

  const renderOdds = (item, runner) => {
    const status = data.status
    const statusLabel = data.statusLabel

    if (
      (status && !!status.localeCompare('OPEN')) ||
      !item?.status.localeCompare('SUSPENDED')
    ) {
      return (
        <div className="SportEvent__market__status">
          {!item?.status.localeCompare('SUSPENDED')
            ? item?.status.toUpperCase()
            : status.toUpperCase()}
        </div>
      )
    }
    if (!isFancy && width >= 1024) {
      return (
        <>
          {
            <OddButton
              market={data}
              isBack={true}
              oddData={{ ...item?.ex?.availableToBack[2], runner }}
              showEmpty
              centered
              showBlock={isRacing ? true : false}
              mainBlock={false}
              marketNum={2}
              setClicked={setClicked}
              clicked={clicked}
            />
          }
          {
            <OddButton
              market={data}
              isBack={true}
              oddData={{ ...item?.ex?.availableToBack[1], runner }}
              showEmpty
              centered
              showBlock={isRacing ? true : false}
              mainBlock={false}
              marketNum={1}
              setClicked={setClicked}
              clicked={clicked}
            />
          }
          <OddButton
            market={data}
            isBack={true}
            oddData={{ ...item?.ex?.availableToBack[0], runner }}
            showEmpty
            centered
            showBlock={isRacing ? true : false}
            marketNum={0}
            setClicked={setClicked}
            clicked={clicked}
          />
          <OddButton
            market={data}
            isBack={false}
            oddData={{ ...item?.ex?.availableToLay[0], runner }}
            showEmpty
            centered
            showBlock={isRacing ? true : false}
            marketNum={0}
            setClicked={setClicked}
            clicked={clicked}
          />
          {
            <OddButton
              market={data}
              isBack={false}
              oddData={{ ...item?.ex?.availableToLay[1], runner }}
              showEmpty
              centered
              showBlock={isRacing ? true : false}
              mainBlock={false}
              marketNum={1}
              setClicked={setClicked}
              clicked={clicked}
            />
          }
          {
            <OddButton
              market={data}
              isBack={false}
              oddData={{ ...item?.ex?.availableToLay[2], runner }}
              showEmpty
              centered
              showBlock={isRacing ? true : false}
              mainBlock={false}
              marketNum={2}
              setClicked={setClicked}
              clicked={clicked}
            />
          }
        </>
        // <></>
      )
    }
    return (
      <>
        {statusLabel &&
        statusLabel == 'Ball Running' &&
        data.mtype == 'INNINGS_RUNS' ? (
          <div className="SportEvent__market__statuslabel">{statusLabel}</div>
        ) : null}
        {isFancy && (
          <OddButton
            market={data}
            isBack={false}
            oddData={{ ...item.lay[0], runner }}
            showEmpty
            centered
            isFancy={isFancy}
          />
        )}
        <OddButton
          market={data}
          isBack={true}
          oddData={{ ...item?.ex?.availableToBack[0], runner }}
          showEmpty
          centered
          isFancy={isFancy}
        />
        {!isFancy && (
          <OddButton
            market={data}
            isBack={false}
            oddData={{ ...item?.ex?.availableToLay[0], runner }}
            showEmpty
            centered
          />
        )}
      </>
    )
  }

  const openLadder = (name, id) => {
    if (
      exposure &&
      exposure?.pnlBySelection &&
      exposure.pnlBySelection[data.id]
    ) {
      dispatch(setSportLadderShow(true))
      dispatch(setSportLadderTitle(name))
      dispatch(setSportLadderId(id))
      dispatch(requestSportLadder())
    }
  }

  const renderInningIcon = () => {
    if (data.mtype == 'INNINGS_RUNS')
      return (
        <span
          onClick={() => openLadder(data.name, data.id)}
          className={`SportMarketStandard__inningIcon ${
            exposure &&
            exposure.pnlBySelection &&
            exposure.pnlBySelection[data.id]
              ? ''
              : 'disabled'
          }`}
        />
      )
    return null
  }

  const renderExposure = (runnerId) => {
    let number = 0,
      calced
    // const isFancy = data.mtype == "INNINGS_RUNS" && data.btype == "LINE"
    const pnlId = data.market_id
    if (exposure && exposure && exposure[pnlId]) {
      let findNumber = exposure[pnlId]?.filter(
        (item) => item.selection_id == runnerId
      )
      number = findNumber[0].user_pl
    }

    let odds =
      data?.market_type === 'BOOKMAKER'
        ? betPlacing.priceActual / 100 + 1
        : betPlacing.priceActual
    if (betPlacing.market === data.market_id) {
      if (betPlacing.runner === runnerId) {
        if (betPlacing.isBack) {
          // const profit = isFancy ? (betPlacing.stake * betPlacing.priceActual / 100).toFixed(2) : (betPlacing.stake * betPlacing.priceActual - betPlacing.stake).toFixed(2)
          const profit = isFancy
            ? betPlacing.stake
            : (betPlacing.stake * odds - betPlacing.stake).toFixed(2)
          calced = (isFancy ? 0 : number) + Number(profit) * (isFancy ? -1 : 1)
        } else {
          const profit = isFancy
            ? betPlacing.stake * (betPlacing.priceActual / 100)
            : (betPlacing.stake * odds - betPlacing.stake).toFixed(2)
          calced = (isFancy ? 0 : number) + Number(profit) * -1
        }
      } else {
        calced = betPlacing.isBack
          ? number - Number(betPlacing.stake)
          : number - Number(betPlacing.stake) * -1
        // calced = number - Number(betPlacing.stake)
      }
    }

    const preCalced = isFancy && number == 0 ? 'Max exposure: ' : ''
    if (number || calced)
      return (
        <div className="SportEvent__market__title__exposure">
          {number ? (
            <div className={`${number > 0 ? 'positive' : 'negative'}`}>
              {getCurrencyFormat(number)}
            </div>
          ) : null}
          {preCalced}
          {(calced || (number && calced == 0)) && (
            <div className={`${calced > 0 ? 'positive' : 'negative'}`}>
              » {getCurrencyFormat(calced)}
            </div>
          )}
        </div>
      )
    return null
  }

  // get eventTypeId
  const history = useHistory()
  const eventTypeId = history?.location?.pathname?.split('/')[2]
  // const eventId = history?.location?.pathname?.split("/")[3];
  // end get eventTypeId

  const [activeIcon, setActiveIcon] = useState([])

  const toggleStar = (id) => {
    setActiveIcon((prevActiveIcon) => {
      // Check if the id is already in the array
      if (prevActiveIcon.includes(id)) {
        // Remove the id from the array
        return prevActiveIcon.filter((iconId) => iconId !== id)
      } else {
        // Add the id to the array
        return [...prevActiveIcon, id]
      }
    })
  }

  const favoritesState = useSelector((state) => state.favorites)

  localStorage.setItem('favorites', JSON.stringify(favoritesState))

  // const isFavorite = () => {
  //   if (
  //     favoritesState?.sportMarkets?.length > 0 &&
  //     data &&
  //     favoritesState?.sportMarkets?.filter((el) => el?.id === data?.id).length
  //   ) {
  //     return true
  //   } else {
  //     return false
  //   }
  // }

  const isFavorite = () => {
    if (
      favoritesState?.favoritesAll?.length > 0 &&
      data &&
      favoritesState?.favoritesAll?.filter((el) => el?.id === data?.id).length
    ) {
      return true
    } else {
      return false
    }
  }

  useEffect(() => {
    const matchIds =
      favoritesState?.favoritesAll?.map((favorite) => favorite.market_id) || []
    setActiveIcon(matchIds)
  }, [favoritesState, data])

  // const handleFavorite = (id, data) => {
  //   // console.log('favitem', item);
  //   if (activeIcon) {
  //     console.log('if active clear')
  //      dispatch(clearfavoritesSportMarkets(data))
  //   } else {
  //     dispatch(addfavoritesSportMarkets(data))
  //   }
  // }

  const handleFavorite = (id, item) => {
    if (activeIcon.includes(id)) {
      dispatch(clearfavoritesItem(item))
    } else {
      dispatch(addfavoritesItem(item))
    }
  }

  const gotoEvent = () => {
    localStorage.setItem('Event', JSON.stringify(data))
    if (data.eventTypeId == '77777')
      history.push(`/casino_live/${data.event.id}`)
    else history.push(`/event-page/${data.eventTypeId}/${data.match_id}`)
  }
  const [openPopUp, setOpenPopUp] = useState(false)
  const [popUpTitle, setPopUpTitle] = useState(null)
  // console.log('popUpTitle', popUpTitle && popUpTitle);

  // let finish = new Date(data?.start)

  const calcMinutes = (time) => {
    return ((new Date(time).getTime() - new Date().getTime()) / 60000).toFixed(
      0
    )
  }

  const addMarketData = (market) => {
    // Check if market_id already exists in state
    const marketExists = selectedRaces.some(
      (item) => item.selectionId === market.selectionId
    )

    if (
      !marketExists &&
      selectedRaces.length < 4 &&
      data?.status !== 'SUSPENDED' &&
      market?.status === 'ACTIVE'
    ) {
      // If it doesn't exist, add the new data to the array
      setSelectedRaces &&
        setSelectedRaces((prevMarkets) => [
          ...prevMarkets,
          {
            ...market,
            match_id: data.match_id,
            match_name: data.match_name,
            match_date: data.match_date,
            market_id: data.market_id,
            name: data.name,
            market_name: data.market_name,
            market_type: data.market_type,
          },
        ])
    } else {
      setSelectedRaces &&
        setSelectedRaces((prevMarkets) =>
          prevMarkets.filter((item) => item.selectionId !== market.selectionId)
        )
      // console.log("Market with this market_id already exists.", market);
    }
  }

  if (width >= 1024) {
    return (
      <section
        className={`SportMarketStandard ${
          data?.runners?.length < 2 ? 'short' : ''
        } ${
          sportid === '1' ||
          sportid === '2378962' ||
          sportid === '2' ||
          sportid === '4' ||
          sportid === '7' ||
          sportid === '4339'
            ? 'moreOdds'
            : ''
        } `}
      >
        {showHeader && !isFancy && (
          <div className="SportMarketStandard__header">
            <div
              className={`SportEvent__market__title ${
                data?.betDelay >= 1 ? 'withDelay' : ''
              }`}
            >
              {history.location.pathname === '/favouritesPage' ? (
                <img
                  style={{ marginLeft: '0', cursor: 'pointer' }}
                  onClick={() => {
                    // dispatch(clearfavoritesSportMarkets(data))
                    dispatch(clearfavoritesItem(data))
                  }}
                  className="starImg"
                  src={StarIconFilled}
                  alt="star icon"
                  loading={'lazy'}
                />
              ) : data?.market_id ? (
                <img
                  style={{ marginLeft: '0', cursor: 'pointer' }}
                  onClick={() => {
                    if (data?.market_id) {
                      toggleStar(data?.market_id)
                      handleFavorite(data?.market_id, data)
                    }
                  }}
                  className="starImg"
                  src={
                    activeIcon.includes(data?.market_id)
                      ? StarIconFilled
                      : StarIcon
                  }
                  alt="star icon"
                  loading={'lazy'}
                />
              ) : null}
              <span
                style={{ cursor: 'pointer' }}
                className="SportEvent__market__title-name"
                onClick={gotoEvent}
              >
                {data?.market_name === 'BOOKMAKER'
                  ? 'Bookmaker'
                  : data?.id?.includes('BR') &&
                    data?.market_name.includes('1st innings o')
                  ? data?.market_name.replace('1st innings o', 'O')
                  : data?.id?.includes('BR') &&
                    data?.market_name.includes('1st innings -')
                  ? data?.market_name
                  : data?.market_name}
              </span>
              {data?.runners?.length === 2 &&
                data?.market_type === 'MATCH_ODDS' && (
                  <button
                    className="cashOutButton"
                    onClick={() =>
                      cashOutData != 0 &&
                      sendCahsOutRequest(data, data.betDelay)
                    }
                  >
                    {' '}
                    CASH OUT {cashOutData != 0 && cashOutData}
                  </button>
                )}

              {/* {data?.betDelay >= 1 ? (
                data && data?.id?.includes("_SB") ?
                  (<div className="SportMarketStandard__title-timer">
                    <div className="hover-before">
                      <img
                        className="SportMarketStandard__title-timer-fasterBetAcceptanceIcon"
                        src={fasterBetAcceptanceIcon}
                        alt="faster Bet Acceptance Icon"
                        loading={"lazy"}
                      />
                    </div>

                    <div className="hover-after">
                      <img
                        className="SportMarketStandard__title-timer-noCommissionIcon"
                        src={noCommissionIcon}
                        alt="no Commission Icon"
                        loading={"lazy"}
                      />
                    </div>
                  </div>) :
                  (<div className="SportMarketStandard__title-timer">
                    <img
                      className="SportMarketStandard__title-timer-img"
                      src={timerIcon}
                      alt="timer icon"
                      loading={"lazy"}
                    />
                    <span className="SportMarketStandard__title-timer-delay">
                  {data.betDelay}s
                </span>
                  </div>)
              ) : null} */}
            </div>

            {data?.status !== 'SUSPENDED' && (
              <div
                className="SportMarketStandard__header__action-wrapper"
                style={{ display: 'contents' }}
              >
                <div
                  className="SportMarketStandard__header__action"
                  style={{ display: 'flex' }}
                >
                  Back
                </div>
                <div
                  className="SportMarketStandard__header__action"
                  style={{ display: 'flex' }}
                >
                  Lay
                </div>
              </div>
            )}
          </div>
        )}
        <div className="SportMarketStandard__markets">
          {data?.status === 'SUSPENDED' &&
            (data?.eventTypeId === '1' ||
              data?.eventTypeId === '2' ||
              data?.eventTypeId === '4' ||
              data?.eventTypeId === '7' ||
              data?.eventTypeId === '2378962' ||
              data?.eventTypeId === '4339') && (
              <div className="SportMarketStandard__markets-suspended">
                <span>SUSPENDED</span>
              </div>
            )}
          {sportLimits?.check_event_limit && (
            <div className="SportMarketStandard__markets-limits">
              {sportLimits &&
                sportLimits.data &&
                sportLimits.data[data?.market_id] && (
                  <span>
                    {' '}
                    Min:{' '}
                    {getCurrencyFormat(
                      sportLimits.data[data?.market_id].market_min_stack
                    )}
                  </span>
                )}
              {sportLimits &&
                sportLimits.data &&
                sportLimits.data[data?.market_id] && (
                  <span>
                    {' '}
                    Max:{' '}
                    {getCurrencyFormat(
                      sportLimits.data[data?.market_id].market_max_stack
                    )}
                  </span>
                )}
            </div>
          )}
          {(sortRunners
            ? data?.runners
                ?.slice()
                .sort((a, b) => a.sort - b.sort)
                .slice(0, full ? undefined : 3)
            : data?.runners
          )?.map((item) => {
            const runner = {
              id: item?.selectionId,
              name: item?.selection_name,
            }
            return (
              <div key={item?.selectionId} className="SportEvent__market">
                <div
                  className={`SportEvent__market__row ${data.status?.toUpperCase()}  ${
                    item?.status
                  } ${item?.metadata?.JOCKEY_NAME ? 'showJockeyName' : ''}`}
                >
                  <div className="SportEvent__market__title">
                    <div
                      className={`SportEvent__market__title__text ${
                        data?.betDelay ? 'withDelay' : ''
                      }`}
                    >
                      {data.sport_id == 7 && (
                        <div
                          onClick={() => addMarketData(item)}
                          className={
                            !selectedRaces.some(
                              (itemSelect) =>
                                itemSelect.selectionId === item.selectionId
                            )
                              ? 'topFilters-item-check'
                              : 'topFilters-item-check checked'
                          }
                        ></div>
                      )}
                      <div>
                        <p>
                          {item.metadata?.CLOTH_NUMBER && (
                            <span className={`SportEvent__market-item-number`}>
                              {item.metadata?.CLOTH_NUMBER &&
                                item.metadata?.CLOTH_NUMBER}
                            </span>
                          )}
                          <span className="SportEvent__market-item-name">
                            {item?.selection_name}
                          </span>
                          {data?.tabGroupName === 'Fancy' && data?.betDelay && (
                            <div className="SportMarketStandard__title-timer">
                              <img
                                className="SportMarketStandard__title-timer-img"
                                src={timerIcon}
                                alt="timer icon"
                                loading={'lazy'}
                              />
                              <span className="SportMarketStandard__title-timer-delay">
                                {data.betDelay}s
                              </span>
                            </div>
                          )}
                        </p>

                        {showJockeyName && item?.metadata?.JOCKEY_NAME && (
                          <p>
                            {item.metadata?.STALL_DRAW && (
                              <span
                                className={`SportEvent__market-item-number`}
                              >
                                (
                                {item.metadata?.STALL_DRAW &&
                                  item.metadata?.STALL_DRAW}
                                )
                              </span>
                            )}
                            <span
                              className={`jockeyName__name  ${
                                !item.metadata?.STALL_DRAW &&
                                showJockeyName &&
                                item?.metadata?.JOCKEY_NAME
                                  ? 'STALL_DRAW-missed'
                                  : ''
                              } `}
                            >
                              {showJockeyName &&
                                item?.metadata?.JOCKEY_NAME &&
                                item?.metadata?.JOCKEY_NAME}
                              {showJockeyName &&
                                !item?.metadata?.JOCKEY_NAME &&
                                eventTypeId &&
                                eventTypeId === '7' &&
                                'Non Runner'}
                            </span>
                          </p>
                        )}
                      </div>
                      {renderExposure(runner?.id)}
                    </div>
                    {item?.status === 'WINNER' && (
                      <span className={`SportEvent__market-showStatus`}>
                        Winner
                      </span>
                    )}
                    {item?.status === 'REMOVED' &&
                      eventTypeId &&
                      eventTypeId === '4339' && (
                        <span
                          className={`SportEvent__market-showStatus greyhound-removed`}
                        >
                          Removed
                        </span>
                      )}
                    {item?.status === 'REMOVED' &&
                      eventTypeId &&
                      eventTypeId === '7' &&
                      data?.status !== 'SUSPENDED' && (
                        <span
                          className={`SportEvent__market-showStatus horse-removed`}
                        >
                          Non Runner {item?.adjustmentFactor?.toFixed(2)}%
                          <span
                            style={{
                              display: 'inline-block',
                              marginLeft: '8px',
                            }}
                          >
                            {new Date(item?.removalDate)
                              .toLocaleString('en-GB', {
                                weekday: 'short',
                                day: 'numeric',
                                month: 'short',
                                hour: 'numeric',
                                minute: 'numeric',
                              })
                              .replaceAll(',', ' ')}
                          </span>
                        </span>
                      )}
                    {renderInningIcon()}
                  </div>
                  {/*{renderOdds(item, runner)}*/}
                  {isRacing && item && item?.status === 'ACTIVE'
                    ? renderOdds(item, runner)
                    : ''}
                  {/*{item.status === "REMOVED" &&*/}
                  {/*  <div className="SportEvent__market-showStatus-wrapper">*/}
                  {/*    <span className={`SportEvent__market-showStatus removed`}>{item?.metadata?.JOCKEY_NAME} {item?.adjustmentFactor?.toFixed(2)}%</span>*/}
                  {/* </div>*/}
                  {/*}*/}
                  {!isRacing && (
                    <div
                      className={`SportEvent__market__odds-wrapper ${
                        isFancy ? 'fancy' : ''
                      }`}
                      style={{ display: 'flex' }}
                    >
                      {renderOdds(item, runner)}
                      {isFancy && (
                        <div className="SportEvent__market__odds-wrapper-max-bets">
                          {besSettings && (
                            <span>
                              Max: {getCurrencyFormat(besSettings?.maxBet)}
                            </span>
                          )}
                          {besSettings && (
                            <span>
                              Max Mkt:{' '}
                              {getCurrencyFormat(besSettings?.maxWinPerMarket)}
                            </span>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
                {renderBetPlacing(runner)}
              </div>
            )
          })}
        </div>
      </section>
    )
  }

  return (
    <section
      className={`SportMarketStandard ${
        data?.runners?.length < 2 ? 'short' : ''
      }`}
    >
      {showHeader &&
        !(data?.mtype == 'INNINGS_RUNS' && data?.btype == 'LINE') && (
          <div className="SportMarketStandard__header">
            {/* <div className="SportEvent__market__title">{data?.name}</div> */}
            <div
              className={`SportEvent__market__title ${
                data?.betDelay >= 1 ? 'withDelay' : ''
              }`}
            >
              <span className="SportEvent__market__title-name">
                {data?.market_name === 'BOOKMAKER'
                  ? 'Bookmaker'
                  : data?.market_name === 'MINI BOOKMAKER'
                  ? 'Mini Bookmaker'
                  : data?.id?.includes('BR') &&
                    data?.market_name.includes('1st innings o')
                  ? data?.market_name.replace('1st innings o', 'O')
                  : data?.id?.includes('BR') &&
                    data?.market_name.includes('1st innings -')
                  ? data?.market_name
                  : data?.market_name.includes('Winner (incl. super over')
                  ? data?.market_name.replace(
                      'Winner (incl. super over)',
                      'Who will win the match ?'
                    )
                  : data?.market_name.includes('1x2')
                  ? data?.market_name.replace('1x2', 'Who will win the match ?')
                  : data?.market_name}{' '}
                <IonIcon className="SportEvent__market__icon" src={infoIcon} />
                {sportLimits &&
                  sportLimits.data &&
                  sportLimits.data[data?.market_id] && (
                    <span className="SportEvent__market__bet-rate">
                      {' '}
                      Min:{' '}
                      {getCurrencyFormat(
                        sportLimits.data[data?.market_id].market_min_stack
                      )}
                    </span>
                  )}{' '}
                {sportLimits &&
                  sportLimits.data &&
                  sportLimits.data[data?.market_id] && (
                    <span className="SportEvent__market__bet-rate">
                      {' '}
                      Max:{' '}
                      {getCurrencyFormat(
                        sportLimits.data[data?.market_id].market_max_stack
                      )}
                    </span>
                  )}
              </span>
              {data?.runners?.length === 2 &&
                data?.market_type === 'MATCH_ODDS' && (
                  <button
                    className="cashOutButton"
                    onClick={() =>
                      cashOutData != 0 &&
                      sendCahsOutRequest(data, data.betDelay)
                    }
                  >
                    {' '}
                    CASH OUT {cashOutData != 0 && cashOutData}
                  </button>
                )}
              {!data?.market_name.includes('Winner (incl. super over') ||
              !data?.market_name === '1x2' ? null : data?.betDelay >= 1 ? (
                data && data?.id?.includes('_SB') ? (
                  <div className="SportMarketStandard__title-timer">
                    <img
                      className="SportMarketStandard__title-timer-fasterBetAcceptanceIcon"
                      src={fasterBetAcceptanceIcon}
                      alt="faster Bet Acceptance Icon"
                      loading={'lazy'}
                      onClick={() => {
                        setOpenPopUp(true)
                        setPopUpTitle('Faster bet acceptance')
                      }}
                    />
                    <img
                      className="SportMarketStandard__title-timer-noCommissionIcon"
                      src={noCommissionIcon}
                      alt="no Commission Icon"
                      loading={'lazy'}
                      onClick={() => {
                        setOpenPopUp(true)
                        setPopUpTitle('No commission')
                      }}
                    />
                  </div>
                ) : (
                  <div className="SportMarketStandard__title-timer">
                    <img
                      className="SportMarketStandard__title-timer-img"
                      src={timerIcon}
                      alt="timer icon"
                      loading={'lazy'}
                    />
                    <span className="SportMarketStandard__title-timer-delay">
                      {data.betDelay}s
                    </span>
                  </div>
                )
              ) : null}
            </div>
            {data?.market_name === '1x2' ||
            data?.market_name.includes('Winner (incl. super over') ||
            data?.market_name.includes('To Win The Toss') ? null : (
              <>
                <div className="SportMarketStandard__header__action">Back</div>
                <div className="SportMarketStandard__header__action">Lay</div>
              </>
            )}
          </div>
        )}

      {/*{(data?.name.includes('Winner (incl. super over') || data?.name.includes('1x2')) && calcMinutes(data?.start) >= 0 && calcMinutes(data?.start) <= 59 && <div className='SportMarketStandard__timer'>Starts in {calcMinutes(data?.start)} mins</div>}*/}
      {/*{(data?.name.includes('Winner (incl. super over') || data?.name.includes('1x2')) && calcMinutes(data?.start) > 59 && <div className='SportMarketStandard__timer'>{new Date(data?.start).toLocaleString("en-GB")}</div>}*/}
      {/* {data?.name.includes('1x2') && calcMinutes(data?.start) >= 0 && calcMinutes(data?.start) <= 59 && <div className='SportMarketStandard__timer'>Starts in {calcMinutes(data?.start)} mins</div>}
      {data?.name.includes('1x2') && calcMinutes(data?.start) > 59 && <div className='SportMarketStandard__timer'>{new Date(data?.start).toLocaleString("en-GB")}</div>} */}

      {data?.market_name.includes('Winner (incl. super over') ||
      data?.market_name === '1x2' ||
      data?.market_name.includes('To Win The Toss') ? (
        <>
          <div className="SportMarketStandard__winner">
            {(sortRunners
              ? data?.runners
                  ?.slice()
                  .sort((a, b) => a.sort - b.sort)
                  .slice(0, full ? undefined : 3)
              : data?.runners
            )?.map((item) => {
              const runner = {
                id: item?.selectionId,
                name: item?.selection_name,
              }
              return (
                <div key={runner.id}>
                  <div
                    style={
                      data?.market_name.includes('Winner (incl. super over')
                        ? { width: '145px' }
                        : data?.market_name.includes('To Win The Toss')
                        ? { width: '145px' }
                        : {}
                    }
                    className="SportMarketStandard__winner-block"
                  >
                    <div className="SportMarketStandard__winner-block-name">
                      {runner.name}
                    </div>
                    {!item?.back[0]?.price ? (
                      <div
                        className={`SportMarketStandard__winner-block${
                          data?.market_name.includes('Winner (incl. super over')
                            ? '-suspended'
                            : data?.market_name.includes('To Win The Toss')
                            ? '-suspended'
                            : '-suspended1'
                        }`}
                      >
                        <span>SUSPENDED</span>
                      </div>
                    ) : (
                      <div
                        className={`SportMarketStandard__winner-block${
                          data?.market_name.includes('Winner (incl. super over')
                            ? '-odds'
                            : data?.market_name.includes('To Win The Toss')
                            ? '-odds'
                            : '-odds1'
                        }`}
                        onClick={() => setWinner(item)}
                      >
                        <OddButton
                          market={data}
                          isBack={true}
                          oddData={{ ...item.back[0], runner }}
                          showEmpty
                          centered
                        />
                      </div>
                    )}
                  </div>
                  <div
                    className={`SportMarketStandard__winner-block${
                      data?.market_name.includes('Winner (incl. super over')
                        ? '-exposure'
                        : data?.market_name.includes('To Win The Toss')
                        ? '-exposure'
                        : '-exposure1'
                    }`}
                  >
                    {renderExposure(runner?.id)}
                  </div>
                </div>
              )
            })}
          </div>
          {renderBetPlacing(winner)}
        </>
      ) : (
        <div className="SportMarketStandard__markets">
          {(sortRunners
            ? data?.runners
                ?.slice()
                .sort((a, b) => a.sort - b.sort)
                .slice(0, full ? undefined : 3)
            : data?.runners
          )?.map((item) => {
            const runner = {
              id: item?.selectionId,
              name: item?.selection_name,
            }
            return (
              <div key={item?.id} className="SportEvent__market">
                <div
                  className={`SportEvent__market__row ${data.status.toUpperCase()}  ${
                    item?.status
                  } ${item?.metadata?.JOCKEY_NAME ? 'showJockeyName' : ''}`}
                >
                  <div className="SportEvent__market__title">
                    <div className={`SportEvent__market__title__text `}>
                      <div>
                        <p>
                          {item.metadata?.CLOTH_NUMBER && (
                            <span className={`SportEvent__market-item-number`}>
                              {item.metadata?.CLOTH_NUMBER &&
                                item.metadata?.CLOTH_NUMBER}
                            </span>
                          )}
                          <span>{item?.selection_name}</span>
                          {item.status === 'REMOVED' && (
                            <span>{item?.status}</span>
                          )}
                        </p>
                        {showJockeyName && item?.metadata?.JOCKEY_NAME && (
                          <p>
                            {item.metadata?.STALL_DRAW && (
                              <span
                                className={`SportEvent__market-item-number`}
                              >
                                (
                                {item.metadata?.STALL_DRAW &&
                                  item.metadata?.STALL_DRAW}
                                )
                              </span>
                            )}
                            <span
                              className={`jockeyName__name ${
                                !item.metadata?.STALL_DRAW &&
                                showJockeyName &&
                                item?.metadata?.JOCKEY_NAME
                                  ? 'STALL_DRAW-missed'
                                  : ''
                              } `}
                            >
                              {showJockeyName &&
                                item?.metadata?.JOCKEY_NAME &&
                                item?.metadata?.JOCKEY_NAME}
                            </span>
                          </p>
                        )}
                      </div>
                      {renderExposure(runner?.id)}
                    </div>
                    {item?.status === 'WINNER' && (
                      <span className={`SportEvent__market-showStatus`}>
                        Winner
                      </span>
                    )}
                    {data?.tabGroupName === 'Fancy' && data?.betDelay && (
                      <div className="SportMarketStandard__title-timer">
                        <img
                          className="SportMarketStandard__title-timer-img"
                          src={timerIcon}
                          alt="timer icon"
                          loading={'lazy'}
                        />
                        <span className="SportMarketStandard__title-timer-delay">
                          {data.betDelay}s
                        </span>
                      </div>
                    )}
                    {renderInningIcon()}
                  </div>
                  {/*{renderOdds(item, runner)}*/}
                  {isRacing && item && item?.status === 'ACTIVE'
                    ? renderOdds(item, runner)
                    : ''}
                  {!isRacing && renderOdds(item, runner)}
                </div>
                {renderBetPlacing(runner)}
              </div>
            )
          })}
        </div>
      )}

      {openPopUp && (
        <SportMarketStandardPopUp
          onDidDismiss={() => setOpenPopUp(false)}
          title={popUpTitle}
        />
      )}
    </section>
  )
}

export default connectSportMarketStandard()(SportMarketStandard)

import './pop-upDelete.scss'
// import { IonButton, IonImg, IonPopover } from '@ionic/react'
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import { setDetailedDepositTransaction } from '../../../store/detailedDepositTransaction/detailedDepositTransaction.slice'
import { useHistory } from 'react-router'
import { BASE_DEV_URL } from '../../../config/api.config'
import getErrorMessage from '../../../helpers/getErrorMessage.helper'
import SuccessWithdrawIcon from '../../../assets/images/SuccessWithdraw.svg'

const DepositBonus = ({ content, open, onDidDismiss }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const token = localStorage.getItem('id_token')

  return (
    <>
      {open && (
        <div className="editPopUpDelete">
          <div
            className="pop-upDelete_wrapper"
            onClick={(e) => {
              if (e.currentTarget === e.target) {
                onDidDismiss(false)
              }
            }}
          >
            <div className="pop-upDelete">
              <div className="pop-upDelete-content">
                <img src={SuccessWithdrawIcon} />
                <div className="successPopUpTitle">Congratulations!</div>
                <div className="successPopUpSubTitle" style={{textAlign: 'center'}}>
                {content}
                </div>
              </div>

              <div className="pop-upDelete-content__buttons">
                <div
                  className="successPopUpButton"
                  onClick={() => {
                    onDidDismiss(false)
                  }}
                >
                  OK, I AGREE !
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default DepositBonus

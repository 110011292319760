import './ConfirmBetPopUp.scss'
import { IonPopover, IonSpinner } from '@ionic/react'
import React, { useState, useEffect } from 'react'
import { connectConfirmBetPopUp } from './ConfirmBetPopUp.connect'
import { toastr } from 'react-redux-toastr'
import { getPlacingText } from '../../../helpers/betPlacing.helper'
import { isConfirmBetPopUpOpenThunk } from '../../../store/betPlacing/betPlacing.thunks'

const ConfirmBetPopUp = ({
  open,
  betPlacing,
  refBetPopover,
  cancel,
  actions,
  sportEventStats,
}) => {
  const [timer, setTimer] = useState(betPlacing.delay)

  useEffect(() => {
    actions.isConfirmBetPopUpOpenThunk(open)
  },[open])


  useEffect(() => {
    setTimer(betPlacing.delay)
  }, [betPlacing.delay, open])
  //betPlacing.betPlacingPopoverStage2,
  useEffect(() => {
    if (timer && betPlacing.betPlacingPopoverStage2 && open) {
      const time = setInterval(() => {
        setTimer((timer) => timer - 1)
      }, 1000)
      return () => clearInterval(time)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timer, betPlacing.betPlacingPopoverStage2, open])

  const submit = async () => {
    actions.setBetPlacingPopoverStage(true)
    actions
      .requestSetOrder(betPlacing)
      .then((res) => {
        if (res.data.status) {
          toastr.success('', res.data?.msg)
        } else {
          toastr.error('', res.data?.msg)
        }
        actions.requestOpenBets()
        actions.requestUnMatchedBets()
        cancel()
        actions.isConfirmBetPopUpOpenThunk(false)
      })
      .catch((e) => {
        cancel()
        actions.isConfirmBetPopUpOpenThunk(false)
        if (e.data?.msg) toastr.error('', e.data?.msg)
        else toastr.error('', e?.msg)
      })
  }

  const submitCashOut = async () => {
    actions.setBetPlacingPopoverStage(true)
    actions.requestCashOut(betPlacing.cashOutId).then((res) => {
      actions.setShowBetPlacingPopover(false);
      const sizeMatched = res.data.result?.result?.placed?.[0].sizeMatched;
      if (sizeMatched > 0) {
        toastr.success("", "Cash-out successful");
        actions.setIsCashOut(false)
        actions.setCashOutId(null)
      }
      // cancel()
    }).catch((e) => {
      // cancel()
      actions.setShowBetPlacingPopover(false);
      if (e[0] && e[0][0] && e[0][0].description) toastr.error("", e[0][0].description);
      else toastr.error("", "Something went wrong");
    });
  }

  const onDismissed = (e) => {
    actions.setIsCashOut(false)
    actions.setCashOutId(null)
    actions.setShowBetPlacingPopover(false)
    actions.setBetPlacingPopoverStage(false)
  }

  const renderContent = () => {
    if (betPlacing.betPlacingPopoverStage2 || (!betPlacing.confirmation && !betPlacing.isCashOut))
      return (
        <div className='bbb'>
          <div className="ConfirmBetPopUpp__timer">
            <IonSpinner
              name="crescent"
              className="ConfirmBetPopUpp__timer__spinner"
            />
            <div className="ConfirmBetPopUpp__timer__seconds">{timer}</div>
          </div>
          <div className="BetPlacing__row ConfirmBetPopUpp__timertext">
            Bet placement in progress
          </div>
        </div>
      )
    return (
      <div className='aaa'>
        { betPlacing.isCashOut ?   
          <>
           <div className="BetPlacing__row ConfirmBetPopUpp__name">
              Do you wish to perform cash out?
           </div>   
           <div className="ConfirmBetPopUpp__buttons">
            <div className="ConfirmBetPopUpp__buttons-cancel" onClick={() => {cancel(); actions.isConfirmBetPopUpOpenThunk(false)}}>
              Cancel
           </div>
          <button
            type="submit"
            onClick={submit}
            className="pushable"
            expand="full"
            mode="md"
          >
            <span className="shadow"></span>
            <span className="edge refreshBtn"></span>
            <span className="front refreshBtn">Cash Out</span>
          </button>
          </div>
         </>  
        :
         <>
           <div className="BetPlacing__row ConfirmBetPopUpp__name">
              Are you sure you want to place your bet?
           </div>
           <div className="ConfirmBetPopUpp__buttons">
            <div className="ConfirmBetPopUpp__buttons-cancel" onClick={() => {cancel(); actions.isConfirmBetPopUpOpenThunk(false)}}>
              Cancel
            </div>
            <button
              type="submit"
              onClick={submit}
              className="pushable"
              expand="full"
              mode="md"
              disabled={
                Number(betPlacing.stake) <= 0 ||
                Number(betPlacing.priceActual) < 1.01
              }
            >
              <span className="shadow"></span>
              <span className="edge refreshBtn"></span>
              <span className="front refreshBtn">Confirm</span>
            </button>
          </div>
       </>
}
      </div>
    )
  }
  return (
    <IonPopover
      isOpen={open}
      ref={refBetPopover}
      backdropDismiss={false}
      className={`ConfirmBetPopUpp ${(sportEventStats && sportEventStats?.hasVideo && localStorage?.getItem('page')?.includes('event-page')) ? "betslipHasVideo" : "" }`}
      onDidDismiss={onDismissed}
    >
      {renderContent()}
     </IonPopover>
  )
}

export default connectConfirmBetPopUp()(ConfirmBetPopUp)

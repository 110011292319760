import React, { useState, useEffect, useRef } from 'react'
import './BalanceInformation.scss'
import { IonItem, IonLabel } from '@ionic/react'
import { useDispatch, useSelector } from 'react-redux'
import { connectBalanceInformation } from './BalanceInformation.connect'
import {
  setBonusPopUp,
  setWalletType,
} from '../../store/bonusPopUp/bonusPopUp.slice'
import exclamationSign from '../../assets/images/menu-icons/exclamationSign.svg'
import { getCurrencyFormat } from '../../helpers/getCurrencyFormat.helper'

const BalanceInformation = ({
  user,
  balance,
  auth,
  isDetailedBalanceOpen,
  actions,
}) => {
  const dispatch = useDispatch()
  const B2C = useSelector((state) => state.B2C)
  const [bonusToggle, setBonusToggle] = useState(
    balance?.useBWallet ? balance?.useBWallet : false
  )
  const [sportsBonusToggle, setSportsBonusToggle] = useState(
    balance?.useSWallet ? balance?.useSWallet : false
  )
  const [casinosBonusToggle, setCasinoBonusToggle] = useState(
    balance?.useCWallet ? balance?.useCWallet : false
  )
  const [isDetailedOpen, setIsDetailedOpen] = useState(false)
  const [elementHeight, setElementHeight] = useState()

  const elementRef = useRef(null)
  useEffect(() => {
    if (elementRef?.current?.clientHeight) {
      setElementHeight(elementRef.current?.clientHeight)
      actions.setBalanceInformationHeight(elementRef.current?.clientHeight)
    }
  }, [elementRef?.current?.clientHeight])

  const changeBonusToggle = (bool) => {
    setBonusToggle(bool)
    actions.changeWalletStatus(user.memberCode, bool, 'B')
  }

  const changeSportsBonusToggle = (bool) => {
    setSportsBonusToggle(bool)
    actions.changeWalletStatus(user.memberCode, bool, 'S')
  }

  const changeCasinoBonusToggle = (bool) => {
    setCasinoBonusToggle(bool)
    actions.changeWalletStatus(user.memberCode, bool, 'C')
  }

  useEffect(() => {
    setBonusToggle(balance?.useBWallet)
    setSportsBonusToggle(balance?.useSWallet)
    setCasinoBonusToggle(balance?.useCWallet)
  }, [balance.useBWallet, balance.useSWallet, balance.useCWallet])

  const getbalance = async () => {
    if (!auth || !user) return
    await actions
      .requestBalance(user.memberCode)
      .catch((e) => console.log('balance error = ', e))
  }

  const renderMenuItem = (
    glyph,
    text,
    additionalClass = '',
    action = () => {},
    withButton = false
  ) => {
    return (
      <IonItem
        className={`right-side-menu__item ${additionalClass}`}
        onClick={action}
      >
        {glyph.length > 1 ? (
          <img src={glyph} className="right-side-menu__item__icon" />
        ) : (
          <span className="right-side-menu__item__icon">{glyph}</span>
        )}
        <IonLabel className="right-side-menu__item__label">{text}</IonLabel>
        {withButton && (
          <div onClick={action} className={additionalClass + ' popUpButton'}>
            i
          </div>
        )}
      </IonItem>
    )
  }

  const actualBalance = () => {
    if (balance && balance.useBWallet) {
      return balance.bWallet.availBalance
    } else if (balance && balance.useSWallet) {
      return balance.sWallet.availBalance.toLocaleString('en-US', {
        minimumFractionDigits: 2,
      })
    } else if (balance && balance.useCWallet) {
      return balance.cWallet.availBalance.toLocaleString('en-US', {
        minimumFractionDigits: 2,
      })
    } else if (balance) {
      return balance.availBalance.toLocaleString('en-US', {
        minimumFractionDigits: 2,
      })
    } else {
      return '0'
    }
  }

  const renderAccountInfoItem = (text, value, color, valueClass = '') => {
    let additionalClass = ''
    if (color) {
      if (value > 0)
        additionalClass = 'right-side-menu__account__info__item__value--green'
      if (value < 0)
        additionalClass = 'right-side-menu__account__info__item__value--red'
    }
    return (
      <div className="right-side-menu__account__info__item">
        <span className="right-side-menu__account__info__item__label">
          {text}
        </span>
        <span
          className={`right-side-menu__account__info__item__value ${additionalClass} ${valueClass}`}
        >
          {value?.toLocaleString('en-US', { minimumFractionDigits: 2 })}
        </span>
      </div>
    )
  }

  return (
    <>
      {auth && (
        <div ref={elementRef} className="balanceInformation">
          <div className="balanceInformationHeader">
            <span className="balanceInformation__title">
              Available Credit:{' '}
              {balance?.balance &&
                getCurrencyFormat(Math.floor(balance?.balance))}
              <span
                style={{ fontWeight: '400' }}
                className="balanceInformation__title"
              >
                {balance && balance.useBWallet
                  ? getCurrencyFormat(balance.bWallet.availBalance)
                  : getCurrencyFormat(balance) && balance.useSWallet
                  ? getCurrencyFormat(balance.sWallet.availBalance)
                  : getCurrencyFormat(balance) && balance.useCWallet
                  ? getCurrencyFormat(balance.cWallet.availBalance)
                  : balance
                  ? balance.availBalance
                    ? getCurrencyFormat(balance.availBalance)
                    : ''
                  : (0.0).toFixed(2)}
              </span>
            </span>
            <div
              className={
                isDetailedOpen
                  ? 'balanceInformation__downButton upImg'
                  : 'balanceInformation__downButton downImg'
              }
              onClick={() => {
                setIsDetailedOpen(!isDetailedOpen)
                actions.setIsDetailedBalanceOpen(!isDetailedBalanceOpen)
              }}
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <path
                  d="M224 416c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L224 338.8l169.4-169.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-192 192C240.4 412.9 232.2 416 224 416z"
                  fill="#2C4F57"
                />
              </svg>
            </div>
            <div
              className={'balanceInformation__downButton refresh'}
              onClick={() => {
                getbalance()
              }}
            >
              <img src="./assets/images/refresh-icon.svg" />
            </div>
          </div>
          {
            <div
              className={
                isDetailedOpen ? 'detailedBalance' : 'detailedBalance hidden'
              }
            >
              {/* {!B2C &&
              <><div className="detailedBalance__title">
              Credit Limit:{' '}
              <span className="detailedBalance__title-sub">
               
                {balance && getCurrencyFormat(balance?.creditLimit || 0)}
              </span>
            </div>
            <div className="detailedBalance__title">
              Winnings:{' '}
              <span
                className={
                  balance && balance.totalBalance > 0
                    ? 'detailedBalance__title-sub detailedGreen'
                    : 'detailedBalance__title-sub detailedRed'
                }
              >
                {balance && getCurrencyFormat(balance?.totalBalance || 0)}
              </span>
            </div></>} */}
              <div className="detailedBalance__title">
                Available Balance:{' '}
                <span
                  className={
                    balance && balance.balance < 0
                      ? 'detailedBalance__title-sub detailedRed'
                      : 'detailedBalance__title-sub'
                  }
                >
                  {/* {balance &&
                  balance.deductedExposure?.toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                  }
                  )} */}
                  {balance && getCurrencyFormat(Math.floor(balance.balance))}
                </span>
              </div>
              <div className="detailedBalance__title">
                Total Net Exposure:{' '}
                <span
                  className={
                    balance && balance.liability < 0
                      ? 'detailedBalance__title-sub detailedRed'
                      : 'detailedBalance__title-sub'
                  }
                >
                  {/* {balance &&
                  balance.deductedExposure?.toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                  }
                  )} */}
                  {balance && getCurrencyFormat(Math.floor(balance.liability))}
                </span>
              </div>

              {B2C && balance?.bWallet && (
                <div>
                  <div
                    className="bonusInformation"
                    onClick={() => {
                      dispatch(setBonusPopUp(true))
                      dispatch(setWalletType('B'))
                    }}
                  >
                    Bonus Wallet
                    <img src={exclamationSign}></img>
                  </div>
                  <div className="detailedBalance__title">
                    Available Credit:
                    <span className="detailedBalance__title-sub">
                      {' '}
                      {/* {balance?.bWallet?.availBalance ? balance.bWallet.availBalance.toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                  }): 0.00.toFixed(2)} */}
                      {balance?.bWallet?.availBalance
                        ? getCurrencyFormat(balance.bWallet.availBalance)
                        : (0.0).toFixed(2)}
                    </span>
                  </div>
                  <div className="detailedBalance__title">
                    Net Exposure:
                    <span
                      className={
                        balance && balance?.bWallet?.deductedExposure > 0
                          ? 'detailedBalance__title-sub detailedGreen'
                          : balance && balance?.bWallet?.deductedExposure < 0
                          ? 'detailedBalance__title-sub detailedRed'
                          : 'detailedBalance__title-sub'
                      }
                    >
                      {' '}
                      {/* {balance?.bWallet?.deductedExposure ? balance.bWallet.deductedExposure.toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                }): 0.00.toFixed(2)} */}
                      {balance?.bWallet?.deductedExposure
                        ? getCurrencyFormat(balance.bWallet.deductedExposure)
                        : (0.0).toFixed(2)}
                    </span>
                  </div>
                  <div className="newTogglerBonus">
                    <span className="detailedBalance__title">Use Bonus</span>
                    <label className="switcher">
                      <input
                        type="checkbox"
                        checked={bonusToggle}
                        onChange={() => changeBonusToggle(!bonusToggle)}
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </div>
              )}

              {B2C && balance?.sWallet && (
                <div>
                  <div
                    className="bonusInformation"
                    onClick={() => {
                      dispatch(setBonusPopUp(true))
                      dispatch(setWalletType('S'))
                    }}
                  >
                    Sports Bonus
                    <img src={exclamationSign}></img>
                  </div>
                  <div className="detailedBalance__title">
                    Available Credit:
                    <span className="detailedBalance__title-sub">
                      {' '}
                      {/* {balance?.sWallet?.availBalance ? balance.sWallet.availBalance.toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                  }): 0.00.toFixed(2)} */}
                      {balance?.sWallet?.availBalance
                        ? getCurrencyFormat(balance.sWallet.availBalance)
                        : (0.0).toFixed(2)}
                    </span>
                  </div>
                  <div className="detailedBalance__title">
                    Net Exposure:
                    <span
                      className={
                        balance && balance?.sWallet?.deductedExposure > 0
                          ? 'detailedBalance__title-sub detailedGreen'
                          : balance && balance?.sWallet?.deductedExposure < 0
                          ? 'detailedBalance__title-sub detailedRed'
                          : 'detailedBalance__title-sub'
                      }
                    >
                      {' '}
                      {/* {balance?.sWallet?.deductedExposure ? balance.sWallet.deductedExposure.toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                }): 0.00.toFixed(2)} */}
                      {balance?.sWallet?.deductedExposure
                        ? getCurrencyFormat(balance.sWallet.deductedExposure)
                        : (0.0).toFixed(2)}
                    </span>
                  </div>
                  <div className="newTogglerBonus">
                    <span className="detailedBalance__title">Use Bonus</span>
                    <label className="switcher">
                      <input
                        type="checkbox"
                        checked={sportsBonusToggle}
                        onChange={() =>
                          changeSportsBonusToggle(!sportsBonusToggle)
                        }
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </div>
              )}

              {B2C && balance?.cWallet && (
                <div>
                  <div
                    className="bonusInformation"
                    onClick={() => {
                      dispatch(setBonusPopUp(true))
                      dispatch(setWalletType('C'))
                    }}
                  >
                    Casino Bonus:
                    <img src={exclamationSign}></img>
                  </div>
                  <div className="detailedBalance__title">
                    Available Credit:
                    <span className="detailedBalance__title-sub">
                      {' '}
                      {/* {balance?.cWallet?.availBalance ? balance.cWallet.availBalance.toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                  }): 0.00.toFixed(2)} */}
                      {balance?.cWallet?.availBalance
                        ? getCurrencyFormat(balance.cWallet.availBalance)
                        : (0.0).toFixed(2)}
                    </span>
                  </div>
                  <div className="detailedBalance__title">
                    Net Exposure:
                    <span
                      className={
                        balance && balance?.cWallet?.deductedExposure > 0
                          ? 'detailedBalance__title-sub detailedGreen'
                          : balance && balance?.cWallet?.deductedExposure < 0
                          ? 'detailedBalance__title-sub detailedRed'
                          : 'detailedBalance__title-sub'
                      }
                    >
                      {' '}
                      {/* {balance?.cWallet?.deductedExposure ? balance.cWallet.deductedExposure.toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                }): 0.00.toFixed(2)} */}
                      {balance?.cWallet?.deductedExposure
                        ? getCurrencyFormat(balance.cWallet.deductedExposure)
                        : (0.0).toFixed(2)}
                    </span>
                  </div>
                  <div className="newTogglerBonus">
                    <span className="detailedBalance__title">Use Bonus</span>
                    <label className="switcher">
                      <input
                        type="checkbox"
                        checked={casinosBonusToggle}
                        onChange={() =>
                          changeCasinoBonusToggle(!casinosBonusToggle)
                        }
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </div>
              )}
            </div>
          }

          {(B2C || localStorage.getItem('B2C')) && (
            <>
              {/*Bonus wallet => B */}
              {/* {(balance?.bWallet) && <div className="right-side-menu__account" onClick={() => dispatch(setWalletType('B'))}>
                
                <div className="right-side-menu__account__info">
                  {renderAccountInfoItem(
                    'Available Credits:',
                    balance?.bWallet?.availBalance ? balance.bWallet.availBalance : 0,
                    false,
                    'bold',
                  )}
                  {renderAccountInfoItem(
                    'Net Exposure:',
                    balance?.bWallet?.deductedExposure ? balance.bWallet.deductedExposure : 0,
                    true,
                    balance?.bWallet?.deductedExposure < 0 ? 'right-side-menu__account__info__item__value--red' : '',
                  )}
                </div>
                <div className="newTogglerBonus">
                  <span className="newTogglerBonus__oneClick">
                    Use Bonus
                  </span>
                  <label className="switcher">
                    <input
                      type="checkbox"
                      checked={bonusToggle}
                      onClick={() => changeBonusToggle(!bonusToggle)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>} */}
              {/*Sports bonus wallet => S */}
            </>
          )}
        </div>
      )}
    </>
  )
}

export default connectBalanceInformation()(BalanceInformation)

import React, { useEffect, useState, useRef } from 'react'
import { useHistory } from 'react-router'
import {
  clearIntervalAsync,
  setIntervalAsync,
} from 'set-interval-async/dynamic'
import { IonList } from '@ionic/react'
import './sportsPage.scss'
import {
  GET_INPLAY_POPULAR_INTERVAL,
  GET_MARKET_DATA_INTERVAL,
} from '../../config/app.config'
import { connectSportsPage } from './sportsPage.connect'
import Promotion from '../../components/Promotion/promotion'
import LoaderContent from '../../components/LoaderContent/LoaderContent'
import { useDispatch, useSelector } from 'react-redux'
import SportPageItem from '../../components/SportPageItem/SportPageItem'
import { setIsAltName } from '../../store/navigationHeaderMenu/navigationHeaderMenu.slice'
import CasinoProvider from '../CasinoProvider/CasinoProvider'

const SportsPage = ({
  lastActivityDate,
  inplayPopular,
  promotions,
  balanceSettings,
  legalAgePopoverShow,
  kycPopoverShow,
  moreRulesPopoverShow,
  timeSettings,
  betPlacing,
  orderCount,
  actions,
  sportPageEvent,
  altName,
  renderIonContent,
  isNavigationOpen,
  auth,
  navigationID,
  moreDetailed,
  ...props
}) => {
  const history = useHistory()
  const [displayedBack, setDisplayedBack] = useState(false)
  const state = useSelector((state) => state)
  const navigationHeaderId = useSelector(
    (state) => state.navigationHeaderMenu.navigationID
  )
  const navigationName = useSelector(
    (state) => state.navigationHeaderMenu.navigationName
  )

  const isMoreDetailed = useSelector(
    (state) => state.navigationHeaderMenu.moreDetailed
  )

  const detailedGroup = useSelector(
    (state) => state.navigationHeaderMenu.detailedGroup
  )

  const detailedGroupName = useSelector(
    (state) => state.navigationHeaderMenu.detailedGroupName
  )

  const menuItem = useSelector((state) => state.menu)

  const isOpenSport = useSelector(
    (state) => state.navigationHeaderMenu.openSport
  )

  const showItemInfo = () => {
    actions.setIsAltName('yess')
  }

  const headerRef = useRef(null)

  useEffect(() => {
    headerRef.current.scrollIntoView({
      block: 'start',
      behavior: 'smooth',
    })
  }, [navigationHeaderId, navigationName])

  useEffect(() => {
    if (!isMoreDetailed || !detailedGroup) {
      getInplayPopular()
      if (navigationHeaderId) {
        actions.requestSportPageEvent(navigationHeaderId)
        if (!auth) {
          const mainTimer = setIntervalAsync(async () => {
            await actions.requestSportPageEvent(navigationHeaderId)
          }, 60000)
          return () => clearIntervalAsync(mainTimer)
        } else {
          const mainTimer = setIntervalAsync(async () => {
            await actions.requestSportPageEvent(navigationHeaderId)
          }, GET_MARKET_DATA_INTERVAL)
          return () => clearIntervalAsync(mainTimer)
        }
      } else {
        history.push('/default-page')
      }
    } else if (isMoreDetailed && detailedGroup) {
      getInplayPopular()
      if (navigationHeaderId) {
        actions.requestSportPageEventDetailed(navigationHeaderId, detailedGroup)
        if (!auth) {
          const mainTimer = setIntervalAsync(async () => {
            await actions.requestSportPageEventDetailed(
              navigationHeaderId,
              detailedGroup
            )
          }, 60000)
          return () => clearIntervalAsync(mainTimer)
        } else {
          const mainTimer = setIntervalAsync(async () => {
            await actions.requestSportPageEventDetailed(
              navigationHeaderId,
              detailedGroup
            )
          }, GET_MARKET_DATA_INTERVAL)
          return () => clearIntervalAsync(mainTimer)
        }
      } else {
        history.push('/default-page')
      }
    }
  }, [
    navigationHeaderId,
    isMoreDetailed,
    detailedGroup,
    history.location.pathname,
  ])

  useEffect(() => {
    if (history.location.pathname === '/sports-page')
      actions.requestOrderCount()
  }, [history.location.pathname, lastActivityDate])

  const getInplayPopular = () => {
    actions
      .requestInplayPopular()
      .catch((e) => console.log('requestInplayPopular error = ', e))
  }

  //
  // useEffect(() => {
  //   if (!inplayPopular && history.location.pathname === '/sports-page')
  //     getInplayPopular()
  //   else {
  //     const timer = setIntervalAsync(async () => {
  //       if (history.location.pathname === '/sports-page') await getInplayPopular()
  //     }, GET_INPLAY_POPULAR_INTERVAL)
  //     return () => clearIntervalAsync(timer)
  //   }
  // }, [inplayPopular, history.location.pathname])

  // const [renderContent, setRenderContent] = useState(renderIonContent)

  useEffect(() => {
    setTimeout(() => {
      actions.setRenderIonContent(true)
    }, 700)
  }, [renderIonContent])

  return (
    <div
      className="default-page__content sportsPage"
      style={{ position: 'relative' }}
    >
      <span
        style={{ position: 'absolute', top: '-135px' }}
        ref={headerRef}
      ></span>
      {navigationID === 'QT' ? (
        <>
          <Promotion />
          <CasinoProvider moreDetailed={moreDetailed} />
        </>
      ) : inplayPopular && inplayPopular ? (
        <>
          <div className="favourites-header">
            <h1>
              {!isMoreDetailed
                ? navigationName && navigationName
                : detailedGroupName}
            </h1>
            <p className="sportsPage-headerBet">
              Betting from 1min before start
            </p>
          </div>
          <div className="sportsPage-subHeader">
            <span>
              {sportPageEvent && sportPageEvent.length >= 1
                ? 'Match'
                : 'There are currently no upcoming events.'}
            </span>
            {sportPageEvent && sportPageEvent[0]?.eventTypeId === '2' && (
              <div className="sportsPage-subHeader-players tennis">
                <span>1</span>
                <span>2</span>
              </div>
            )}
            {sportPageEvent &&
              (sportPageEvent[0]?.eventTypeId === '1' ||
                sportPageEvent[0]?.eventTypeId === '4') && (
                <div className="sportsPage-subHeader-players cricket-football">
                  <span>1</span>
                  <span>X</span>
                  <span>2</span>
                </div>
              )}
          </div>

          {renderIonContent ? (
            <IonList className="inplay-item-list">
              {sportPageEvent &&
                [...sportPageEvent] 
                  .sort(
                    (a, b) =>
                      new Date(a?.match_date).getTime() -
                      new Date(b?.match_date).getTime()
                  )
                  .map((itemData, index) => {
                    return (
                      <SportPageItem
                        type={null}
                        betPlacing={betPlacing}
                        timeSettings={timeSettings}
                        balanceSetting={balanceSettings}
                        displayedBack={displayedBack}
                        setDisplayedBack={setDisplayedBack}
                        key={index}
                        sportItemData={itemData}
                        onCasinoOpen={(id) => {
                          actions.requestLiveCasinoID(id)
                          history.push(`/live_casino_slot/${id}`)
                        }}
                      />
                    )
                  })}
            </IonList>
          ) : null}
          <Promotion />
        </>
      ) : (
        <LoaderContent />
      )}
    </div>
  )
}

export default connectSportsPage()(SportsPage)

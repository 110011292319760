import './DesktopBetPlacing.scss'
import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IonInput } from '@ionic/react'
import { connectDesktopBetPlacing } from './DesktopBetPlacing.connect'
import { toastr } from 'react-redux-toastr'
import { BetPopoverContext } from '../../context'
import { getPlacingText } from '../../helpers/betPlacing.helper'
import { setLoginNew } from '../../store/LoginNew/loginNew.slice'
import { getCurrencyFormat } from '../../helpers/getCurrencyFormat.helper'

const DesktopBetPlacing = ({
  auth,
  betPlacing,
  presetStakeSettings,
  actions,
  balance,
  sportLimits
}) => {
  const dispatch = useDispatch()
  const cancelBetPopover = useContext(BetPopoverContext)
  const [incremented, setIncremented] = useState(false)
  const [styles, setStyles] = useState({ transform: 'translateY(-100%)' })
  useEffect(() => {
    setTimeout(() => setStyles({ transform: 'translateY(0px)' }), 200)
  }, [])

  const isPopUpOpenDispatch = useSelector(
    (state) => state.editStakesPopUp.isOpen
  )

  useEffect(()=>{
    if (auth) {
    actions.requestStack()
    }
  }, [auth])

  const profit = (e) => {
    let odds = betPlacing?.eventName === "Bookmaker"? (betPlacing.priceActual /100)+1  : betPlacing.priceActual
    const type = betPlacing.isBack ? 'Profit' : 'Liability'
    const isFancy = betPlacing.line
    const stake = betPlacing.stake
      ? isFancy
        ? Number((betPlacing.stake * betPlacing.runner) / 100).toFixed(2)
        : Number(
            betPlacing.stake * odds - betPlacing.stake
          ).toFixed(2)
      : '0.00'
    if (e == 1) {
      return `${type}: ${getCurrencyFormat(stake)}`
    } else {
      return getCurrencyFormat(stake)
    }
  }

  const calculateStack = (market, isBack) => {
    if (isBack) {
        return Number(market?.ex?.availableToBack[0]?.price); // example stack value
    } else {
        return Number(market?.ex?.availableToLay[0]?.price);; // different stack value
    }
};

  const submit = async () => {
    if (betPlacing.confirmation) actions.setBetPlacingPopoverStage(false)
    else {
      // actions.setBetPlacingPopoverStage(true)
      if (betPlacing.line) {
        if (betPlacing.price <= 1) {
          toastr.error('', 'Bhaw cannot be less than 1.')
          return;
        }
        actions
          .requestSetFancyOrder(betPlacing)
          .then((res) => {
            // const text = getPlacingText(betPlacing, res)
            if (res?.data) {
              res?.data?.status
                ? toastr.success('', res?.data?.msg)
                : toastr.error('', res?.data?.msg)
            }
            actions.requestOpenBets()
            actions.requestUnMatchedBets()
            cancelBetPopover()
          })
          .catch((e) => {
            cancelBetPopover()
            if (e?.msg) toastr.error('', e?.msg)
            else if (e[0] && e[0][0] && e[0][0].description)
              toastr.error('', e[0][0].description)
            else toastr.error('', 'Something went wrong')
          })
      } else if (betPlacing?.marketAll?.isHrBet) {
        let selection = betPlacing?.marketAll?.selection;
        let stake = Number(betPlacing.stake);
        let isBack = betPlacing?.isBack;
        let totalOdds =  isBack ? betPlacing.marketAll?.totalOddsBack.toFixed(2) : betPlacing.marketAll?.totalOddsLay.toFixed(2);

        let equalAmount = isBack ? stake/totalOdds : stake/totalOdds;

        const newArray = selection.map(market => ({
          selection_id: market.selectionId,
          selection_name: market.selection_name,
          market_id: market.market_id,
          odds: calculateStack(market, isBack),
          is_back: isBack ? 1 : 0,
          stack: Number((calculateStack(market, isBack) * equalAmount).toFixed(0))
      }));

        actions
        .requestHROrder(newArray)
        .then((res) => {
          if (res?.data) {
            res?.data?.status
              ? toastr.success('', res?.data?.msg)
              : toastr.error('', res?.data?.msg)
          }
          cancelBetPopover()
          actions.requestOpenBets()
          actions.requestUnMatchedBets()
        })
        .catch((e) => {
          cancelBetPopover()
          if (e?.msg) toastr.error('', e?.msg)
          else toastr.error('', 'Something went wrong')
        })
      } else {
        actions
          .requestSetOrder(betPlacing)
          .then((res) => {
            // const text = getPlacingText(betPlacing, res)
            if (res?.data) {
              res?.data?.status
                ? toastr.success('', res?.data?.msg)
                : toastr.error('', res?.data?.msg)
            }
            cancelBetPopover()
            actions.requestOpenBets()
            actions.requestUnMatchedBets()
          })
          .catch((e) => {
            cancelBetPopover()
            if (e?.msg) toastr.error('', e?.msg)
            else toastr.error('', 'Something went wrong')
          })
      }
    }
    actions.setShowBetPlacingPopover(true)
  }

  const ripple = (event) => {
    const elem = event.currentTarget

    var rect = event.target.getBoundingClientRect()
    let x = event.clientX - elem.offsetLeft
    let y = rect.height - rect.bottom + event.clientY
    let rippleElement = document.createElement('span')
    rippleElement.style.left = x + 'px'
    rippleElement.style.top = y + 'px'
    elem.appendChild(rippleElement)
    setTimeout(function () {
      rippleElement.remove()
    }, 500)
  }

  const changeOddRipple = (event, mod, isChanging = 1) => {
    const ev = { ...event }
    ripple(event)
    changeOdd(mod, isChanging)
  }

  const changeOdd = (mod, isChanging = 1) => {
    let step, dec
    const odd = Number(betPlacing.priceActual)
    switch (true) {
      case odd < 2: {
        step = 0.01
        dec = 2
        break
      }
      case odd < 3: {
        step = 0.02
        dec = 2
        break
      }
      case odd < 4: {
        step = 0.05
        dec = 2
        break
      }
      case odd < 6: {
        step = 0.1
        dec = 2
        break
      }
      case odd < 10: {
        step = 0.2
        dec = 2
        break
      }
      case odd < 20: {
        step = 0.5
        dec = 1
        break
      }
      case odd < 30: {
        step = 1
        dec = 0
        break
      }
      case odd < 50: {
        step = 2
        dec = 0
        break
      }
      case odd < 100: {
        step = 5
        dec = 0
        break
      }
      default: {
        step = 10
        dec = 0
      }
    }
    let newVal = +(odd + step * mod * isChanging).toFixed(2)
    if (newVal < 1.01) newVal = 1.01
    else if (newVal > 1000) newVal = 1000
    else if (!Number.isInteger(newVal / step))
      newVal = newVal - (newVal % step) + step
    newVal = Number(newVal.toFixed(dec))
    actions.setPriceActual(newVal)
  }

  const getPriseFor_SB = (marketId, price) => {
    return marketId && marketId.slice(marketId.length - 3) === '_SB'
      ? ((price - 1) * 100).toFixed(2).replace('.00', '')
      : price
  }

  // another variant
  const renderOdd = () => {
    if (betPlacing.mtype == 'INNINGS_RUNS' && betPlacing.btype == 'LINE')
      return (
        <div className="DesktopBetPlacing__odd__text DesktopBetPlacing__odd__text--small">
          <div className="small1">{betPlacing.priceActual}</div>
          <div className="small2">{betPlacing.line}</div>
        </div>
      )
    if (
      betPlacing.mtype == 'MATCH_ODDS_SB' ||
      betPlacing.eventName == 'BOOKMAKER'
    )
      return (
        <div className="DesktopBetPlacing__odd__text">
          {getPriseFor_SB(betPlacing.market, betPlacing.priceActual)}
        </div>
      )
      let unmatchBet = sportLimits?.data[betPlacing?.market]?.unmatch_bet_allowed && sportLimits?.data[betPlacing.market]?.market_type ===  "MATCH_ODDS";
    return (
      <>
        <IonInput
          type="number"
          autocomplete="false"
          autoCorrect="false"
          className="DesktopBetPlacing__input DesktopBetPlacing__odd__input"
          value={betPlacing.priceActual}
          onIonChange={(e) => {unmatchBet &&actions.setPriceActual(e.detail.value)}}
          onBlur={() => changeOdd(1, 0)}
          disabled={!unmatchBet} 
        ></IonInput>
        <div className="DesktopBetPlacing__changeodd-buttonsCol">
          <button
            className={`DesktopBetPlacing__changeodd DesktopBetPlacing__changeodd-buttonsCol-plus ${
              betPlacing.isBack ? '' : 'lay'
            }`}
            onClick={(event) => unmatchBet && changeOddRipple(event, 1)}
            mode="md"
          ></button>
          <button
            className={`DesktopBetPlacing__changeodd DesktopBetPlacing__changeodd-buttonsCol-minus ${
              betPlacing.isBack ? '' : 'lay'
            }`}
            onClick={(event) => unmatchBet && changeOddRipple(event, -1)}
            mode="md"
          ></button>
        </div>
      </>
    )
  }

  const renderOddTitle = () => {
    if (betPlacing.mtype == 'INNINGS_RUNS' && betPlacing.btype == 'LINE')
      return (
        <div className="DesktopBetPlacing__row DesktopBetPlacing__row f10">
          <div className="small1">Odds (H-J)</div>
          <div className="small2">Runs</div>
        </div>
      )
    return (
      <div
        style={
          betPlacing.mtype == 'MATCH_ODDS_SB' || betPlacing.mtype == 'BOOKMAKER'
            ? { paddingLeft: '5px' }
            : {}
        }
        className="DesktopBetPlacing__row f10"
      >
        Odds
      </div>
    )
  }

  useEffect(() => {
    if (incremented) setTimeout(() => setIncremented(false), 300)
  }, [incremented])

  const increment = (i) => {
    setIncremented(true)
    actions.setStake(
      (betPlacing.stake ? Number(betPlacing.stake) : 0) +
        presetStakeSettings?.settings[i]
    )
  }
  const incrementNotAuth = (i) => {
    setIncremented(true)
    actions.setStake((betPlacing.stake ? Number(betPlacing.stake) : 0) + i)
  }

  return (
    <div className="DesktopBetPlacing-container">
      {betPlacing?.market ? (
        <>
          <div className="DesktopBetPlacing__topMark">
            <div
              className={
                betPlacing?.isBack
                  ? 'DesktopBetPlacing__topMark-back'
                  : 'DesktopBetPlacing__topMark-lay'
              }
            ></div>
            <span className="DesktopBetPlacing__topMark-title">
              {betPlacing?.isBack ? 'Back' : 'Lay'}
            </span>
          </div>
          <div className="DesktopBetPlacing__matchName">
            {betPlacing.startTime &&
              `${new Date(betPlacing?.startTime).toLocaleTimeString('en-GB', {
                hour: '2-digit',
                minute: '2-digit',
              })}  `}
            {betPlacing?.eventName &&
              `${betPlacing?.eventName?.replace('${HH:mm}', '')}`}
          </div>
          <div
            className={`DesktopBetPlacing ${
              betPlacing?.isBack
                ? 'DesktopBetPlacing--back'
                : 'DesktopBetPlacing--lay'
            }`}
          >
            <div className="DesktopBetPlacing__betName">
              <span>{betPlacing?.runnerName}</span>
              <span style={{ fontSize: '10px' }}>
                Max Market:{' '}
                {(betPlacing?.maxBet &&
                  getCurrencyFormat(betPlacing?.maxBet)) ||
                  '5,00,000'}
              </span>
            </div>
            <div className="DesktopBetPlacing__row">
              <div
                className={`DesktopBetPlacing__col ${
                  betPlacing?.mtype == 'INNINGS_RUNS' &&
                  betPlacing?.btype == 'LINE'
                    ? 'DesktopBetPlacing__col--small'
                    : ''
                }`}
              >
                {renderOddTitle()}
                <div className="DesktopBetPlacing__row">
                  <div className="DesktopBetPlacing__odd">{renderOdd()}</div>
                </div>
              </div>
              <div
                className={`DesktopBetPlacing__stake ${
                  (betPlacing.mtype == 'INNINGS_RUNS' &&
                    betPlacing.btype == 'LINE') ||
                  true
                    ? 'DesktopBetPlacing__col--large'
                    : ''
                }`}
              >
                <div className="DesktopBetPlacing__row f10">
                  <div>Stake</div>
                </div>
                <div className="DesktopBetPlacing__row">
                  <div className="DesktopBetPlacing__stake">
                    <IonInput
                      type="number"
                      inputmode="decimal"
                      className={`DesktopBetPlacing__input DesktopBetPlacing__stake__input DesktopBetPlacing__stake__input--rounded ${
                        incremented ? 'incremented' : ''
                      }`}
                      style={
                        Number(betPlacing.stake) <= 0
                          ? { border: '2px solid #B2493E' }
                          : {}
                      }
                      placeholder={`Min: ${betPlacing?.minBet || ''}`}
                      value={+betPlacing.stake === 0 ? '' : +betPlacing.stake}
                      onIonChange={(e) => {
                        if (auth) {
                          if (Number(e.target.value) >= betPlacing?.minBet) {
                            actions.setStake(
                              String(e.target.value).replace(
                                /^([0-9]*[.,][0-9]{2})([0-9]+)$/,
                                '$1'
                              )
                            )
                          } else {
                            Number(e.target.value) <= betPlacing?.minBet
                              ? actions.setStake(String(betPlacing?.minBet))
                              : actions.setStake(
                                  String(e.target.value).replace(
                                    /^([0-9]*[.,][0-9]{2})([0-9]+)$/,
                                    '$1'
                                  )
                                )
                          }
                        } else {
                          if (
                            Number(e.target.value) &&
                            Number(e.target.value) < 500000
                          ) {
                            actions.setStake(
                              String(e.target.value).replace(
                                /^([0-9]*[.,][0-9]{2})([0-9]+)$/,
                                '$1'
                              )
                            )
                          } else {
                            if (Number(e.target.value) > 500000) {
                              actions.setStake(
                                String(500000).replace(
                                  /^([0-9]*[.,][0-9]{2})([0-9]+)$/,
                                  '$1'
                                )
                              )
                            }
                          }
                        }
                      }}
                    ></IonInput>
                  </div>
                </div>
              </div>
              <div
                className={`DesktopBetPlacing__col profit--column ${
                  betPlacing?.mtype == 'INNINGS_RUNS' &&
                  betPlacing?.btype == 'LINE'
                    ? 'DesktopBetPlacing__col--small'
                    : ''
                }`}
              >
                <div
                  style={{ padding: '0' }}
                  className="DesktopBetPlacing__row f10"
                >
                  <div>{betPlacing?.isBack ? 'Profit' : 'Liability'}</div>
                </div>
                <div className="DesktopBetPlacing__row">
                  <div
                    style={{ margin: '0' }}
                    className="DesktopBetPlacing__odd"
                  >
                    <div className="DesktopBetPlacing__odd__text">
                      {profit(2)}
                    </div>
                  </div>
                </div>
              </div>
              <div className="DesktopBetPlacing__col-delete">
                <div
                  className="DesktopBetPlacing__col-delete-btn"
                  onClick={() => actions.setMarket(null)}
                ></div>
              </div>
            </div>

            <div className="DesktopBetPlacing__row DesktopBetPlacing__row--actions">
            <div className="DesktopBetPlacing__row--actions-row">
                <button
                  className="DesktopBetPlacing__addstake"
                  shape="round"
                  mode="md"
                  onClick={() => {
                    // if(auth) increment(0)
                    // else
                    incrementNotAuth(presetStakeSettings && presetStakeSettings.settings
                      ? (presetStakeSettings?.settings[0])
                      :300)
                  }}
                >
                  +
                  {presetStakeSettings && presetStakeSettings.settings
                    ? getCurrencyFormat(presetStakeSettings?.settings[0])
                    : '300'}
                </button>
                <button
                  className="DesktopBetPlacing__addstake"
                  shape="round"
                  mode="md"
                  onClick={() => {
                    // if(auth) increment(1)
                    // else
                    incrementNotAuth(presetStakeSettings && presetStakeSettings.settings
                      ? (presetStakeSettings?.settings[1])
                      :500)
                  }}
                >
                  +
                  {presetStakeSettings && presetStakeSettings.settings
                    ? getCurrencyFormat(presetStakeSettings?.settings[1])
                    : '500'}
                </button>
                <button
                  className="DesktopBetPlacing__addstake"
                  shape="round"
                  mode="md"
                  onClick={() => {
                    // if(auth) increment(2)
                    // else
                    incrementNotAuth(presetStakeSettings && presetStakeSettings.settings
                      ? (presetStakeSettings?.settings[2])
                      :1000)
                  }}
                >
                  +
                  {presetStakeSettings && presetStakeSettings.settings
                    ? getCurrencyFormat(presetStakeSettings?.settings[2])
                    : '1,000'}
                </button>
                <button
                  className="DesktopBetPlacing__addstake"
                  shape="round"
                  mode="md"
                  onClick={() => {
                    // if(auth) increment(2)
                    // else
                    incrementNotAuth(presetStakeSettings && presetStakeSettings.settings
                      ? (presetStakeSettings?.settings[3])
                      : 1200)
                  }}
                >
                  +
                  {presetStakeSettings && presetStakeSettings.settings
                    ? getCurrencyFormat(presetStakeSettings?.settings[3])
                    : '1,200'}
                </button>
                <button
                  className="DesktopBetPlacing__addstake"
                  shape="round"
                  mode="md"
                  onClick={() => {
                    // if(auth) increment(0)
                    // else
                    incrementNotAuth(presetStakeSettings && presetStakeSettings.settings
                      ? (presetStakeSettings?.settings[4])
                      :1500)
                  }}
                >
                  +
                  {presetStakeSettings && presetStakeSettings.settings
                    ? getCurrencyFormat(presetStakeSettings?.settings[4])
                    : '1500'}
                </button>
              </div>
              <div className="DesktopBetPlacing__row--actions-row">
                <button
                  className="DesktopBetPlacing__addstake"
                  shape="round"
                  mode="md"
                  onClick={() => {
                    // if(auth) increment(1)
                    // else
                    incrementNotAuth(presetStakeSettings && presetStakeSettings.settings
                      ? (presetStakeSettings?.settings[5])
                      :2500)
                  }}
                >
                  +
                  {presetStakeSettings && presetStakeSettings.settings
                    ? getCurrencyFormat(presetStakeSettings?.settings[5])
                    : '2,500'}
                </button>
                <button
                  className="DesktopBetPlacing__addstake"
                  shape="round"
                  mode="md"
                  onClick={() => {
                    // if(auth) increment(2)
                    // else
                    incrementNotAuth(presetStakeSettings && presetStakeSettings.settings
                      ? (presetStakeSettings?.settings[6])
                      : 3000)
                  }}
                >
                  +
                  {presetStakeSettings && presetStakeSettings.settings
                    ? getCurrencyFormat(presetStakeSettings?.settings[6])
                    : '3,000'}
                </button>
                <button
                  className="DesktopBetPlacing__addstake"
                  shape="round"
                  mode="md"
                  onClick={() => {
                    // if(auth) increment(3)
                    // else
                    incrementNotAuth(presetStakeSettings && presetStakeSettings.settings
                      ? (presetStakeSettings?.settings[7])
                      :5000)
                  }}
                >
                  +
                  {presetStakeSettings && presetStakeSettings.settings
                    ? getCurrencyFormat(presetStakeSettings?.settings[7])
                    : '5,000'}
                </button>
                <button
                  className="DesktopBetPlacing__addstake"
                  shape="round"
                  mode="md"
                  onClick={() => {
                    // if(auth) increment(4)
                    // else
                    incrementNotAuth(presetStakeSettings && presetStakeSettings.settings
                      ? (presetStakeSettings?.settings[8])
                      :10000)
                  }}
                >
                  +
                  {presetStakeSettings && presetStakeSettings.settings
                    ? getCurrencyFormat(presetStakeSettings?.settings[8])
                    : '10,000'}
                </button>
                <button
                  className="DesktopBetPlacing__addstake"
                  shape="round"
                  mode="md"
                  onClick={() => {
                    // if(auth) increment(5)
                    // else
                    incrementNotAuth(presetStakeSettings && presetStakeSettings.settings
                      ? (presetStakeSettings?.settings[9])
                      : 25000)
                  }}
                >
                  +
                  {presetStakeSettings && presetStakeSettings.settings
                    ? getCurrencyFormat(presetStakeSettings?.settings[9])
                    : '25,000'}
                </button>
              </div>
              <div className="DesktopBetPlacing__row--actions-row">
              <button
                  className="DesktopBetPlacing__addstake"
                  shape="round"
                  mode="md"
                  onClick={() => {
                    if (auth) {
                      incrementNotAuth(
                        // balance.balance
                        presetStakeSettings.settings
                          ? Math.min(...presetStakeSettings.settings)
                          : 100
                      )
                    } else {
                      incrementNotAuth(500000)
                    }
                  }}
                >
                  MIN
                </button>
                <button
                  className="DesktopBetPlacing__addstake"
                  shape="round"
                  mode="md"
                  onClick={() => {
                    if (auth) {
                      incrementNotAuth(
                        // balance.balance
                        presetStakeSettings.settings
                          ? Math.max(...presetStakeSettings.settings)
                          : 500000
                      )
                    } else {
                      incrementNotAuth(500000)
                    }
                  }}
                >
                  MAX
                </button>
                <button
                  className="DesktopBetPlacing__addstake"
                  shape="round"
                  mode="md"
                  onClick={() => {
                    if (auth) {
                      actions.setIsOpen(!isPopUpOpenDispatch)
                    } else {
                      dispatch(setLoginNew(true))
                    }
                  }}
                >
                  Edit
                </button>
                <div
                  className="DesktopBetPlacing__row--actions-row-clear"
                  onClick={() => actions.setStake(0)}
                >
                  Clear
                </div>
              </div>
            </div>
            {betPlacing.priceActual !== betPlacing.price && (
              <div className="DesktopBetPlacing__changedOdds">
                <div className="DesktopBetPlacing__row DesktopBetPlacing__row-oddchanged">
                  The Odds of your selection have changed
                </div>
                <button
                  className="DesktopBetPlacing__changedOdds-accept"
                  type="button"
                  onClick={() => {
                    actions.setPrice(betPlacing.priceActual)
                  }}
                >
                  Accept Changes
                </button>
              </div>
            )}
          </div>
          <div
            style={{ fontWeight: '500' }}
            className="DesktopBetPlacing__matchName "
          >
            Liability:{' '}
            {betPlacing.isBack
              ? getCurrencyFormat(betPlacing.stake)
              : profit(2)}
          </div>
          <div className="DesktopBetPlacing__bottomBtns">
            <button
              className="DesktopBetPlacing__bottomBtns-remove"
              type="button"
              onClick={() => actions.setMarket(null)}
            >
              Remove All
            </button>
            {auth ? (
              <button
                className="DesktopBetPlacing__bottomBtns-placeBet"
                type="submit"
                onClick={submit}
                disabled={
                  Number(betPlacing.stake) <= 0 ||
                  Number(betPlacing.priceActual) < 1.01 ||
                  (betPlacing.priceActual !== betPlacing.price &&
                    (betPlacing.mtype == 'MATCH_ODDS_SB' ||
                      betPlacing.mtype == 'BOOKMAKER' ||
                      betPlacing.mtype == 'INNINGS_RUNS'))
                }
              >
                Place Bets
              </button>
            ) : (
              <button
                className="DesktopBetPlacing__bottomBtns-placeBet"
                type="submit"
                onClick={() => {
                  dispatch(setLoginNew(true))
                }}
              >
                Log In
              </button>
            )}
          </div>
          <div
            className="DesktopBetPlacing__confirm"
            onClick={() => actions.setConfirmation(!betPlacing.confirmation)}
          >
            <div
              className={`DesktopBetPlacing__confirm-checkbox ${
                betPlacing.confirmation ? 'checked' : ''
              }`}
            ></div>
            <div className="DesktopBetPlacing__confirm-title">
              Confirm bets before placing
            </div>
          </div>
        </>
      ) : (
        <div className={`DesktopBetPlacing__empty-message`}>
          Click on the odds to add selections to the betslip.
        </div>
      )}
    </div>
  )
}

export default connectDesktopBetPlacing()(DesktopBetPlacing)

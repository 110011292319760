import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router'
import axios from 'axios'
import { toastr } from 'react-redux-toastr'
import { IonContent, IonPage, IonHeader } from '@ionic/react'
import './Withdrawal.scss'
import PopUPDelete from './DeletePopUp'
import Toolbar from '../../components/Toolbar/Toolbar'
import QuestionImg from '../../assets/images/questionImg.svg'
import deleteButton from '../../assets/images/deleteButton.svg'
import PopUPWithdrawalHelp from './WithdrawalHelpPopUp'
import { connectWithdrawal } from './Withdrawal.connect'
import { BASE_DEV_URL } from '../../config/api.config'
import FooterDesktop from '../../components/FooterDesktop/FooterDesktop'
import getErrorMessage from '../../helpers/getErrorMessage.helper'
import SuccessPopUp from './SuccessWithdrawPopoup'
import eye from '../../assets/images/eye.svg'
import eyeOff from '../../assets/images/eyeOff.svg'
import { USER_DATA } from '../../config/app.config'

const Withdrawal = ({ user, actions, balance, elementsParams, ...props }) => {
  const history = useHistory()
  const token = localStorage.getItem('id_token')

  const [withdrawAccount, setWithdrawAccount] = useState('')
  const [message, setMessage] = useState('')
  const [digits, setDigits] = useState('')
  const [accountNumber, setAccountNumber] = useState('')
  const [infoIFSC, setInfoIFSC] = useState({})
  const [withdrawalAmount, setwithdrowalAmount] = useState(null)
  const [withdrawalIFSC, setWithdrawalIFSC] = useState()
  const [withdrawalAccNumber, setWithdrawalAccNumber] = useState()
  const [withdrawalAccName, setWithdrawalAccName] = useState()
  const [confirmAccNumber, setConfirmAccNumber] = useState('')
  const [withdrawalBankName, setWithdrawalBankName] = useState()
  const [isRulesAccept, setIsRulesAccept] = useState(false)
  const [isNamesMatch, setisNamesMatch] = useState(false)
  const [isEnoughMoney, setIsEnoughMoney] = useState(false)
  const [itemIFSC, setItemIFSC] = useState()
  const [nameMessage, setNameMessage] = useState('')
  const [bankMessage, setBankMessage] = useState('')
  const [openPopUpDelete, setOpenPopUpDelete] = useState(false)
  const [openPopUpHelp, setOpenPopUpHelp] = useState(false)
  const [inputesFromHistory, setInputesFromHistory] = useState(false)
  const [inputesCustom, setInputesCustom] = useState(false)
  const [submitBtnDisabled, setSubmitBtnDisabled] = useState(false)
  const [shake, setShake] = useState(false)
  const [showSuccessPopUP, setShowSuccessPopUP] = useState(false)
  const [userValidationData, setUserValidationData] = useState(null)
  const [otpVerif, setOtpVerif] = useState(true)
  const [needWait, setNeedWait] = useState(false)
  const [moreThenFive, setMoreThenFive] = useState(false)
  const [accNoEroor, setAccNoEroor] = useState('')
  const [showNumber, setShowNumber] = useState(false)
  const [showConfirmNo, setShowConfirmNo] = useState(false)
  const [parentMethodId, setParentMethodId] = useState(null)
  const [accountInfo, setAccountInfo] = useState()
  const [depositMethod, setDepositMethod] = useState('')

  const data = {
    amount: withdrawalAmount,
    acNo: withdrawalAccNumber,
    IFSC: withdrawalIFSC,
    bankName: withdrawalBankName,
    branchName: infoIFSC?.BRANCH,
    accountName: withdrawalAccName,
  }

  const getUserVerification = async () => {
    const user = await actions.requestProfile()
    setUserValidationData(user)
    return user
  }
  useEffect(() => {
    getUserVerification()
  }, [])

  const requestPayment = async (id) => {
    const userData = localStorage.getItem(USER_DATA)
    const userDetail = JSON.parse(userData)

    const payload = {
      user_id: userDetail?._id,
      parent_id: userDetail?.parent_id,
      amount: data.amount,
      crdr: 2,
      remark: 'wallet',
      payment_method_id: id,
    }

    return await axios
      .post(BASE_DEV_URL + 'wallet/walletchipOutV2', payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.data.status) {
          setTimeout(() => {
            setSubmitBtnDisabled(false)
            setShowSuccessPopUP(true)
          }, 500)
        } else {
          setNeedWait(true)
          setSubmitBtnDisabled(false)
          toastr.error('Error', response.data?.msg)
        }
      })
      // .catch((error) => toastr.error('Error', error?.response?.data?.result))
      .catch((error) => {
        setNeedWait(true)
        setSubmitBtnDisabled(false)
        toastr.error('Error', error?.response?.data?.msg)
        const errorMsg = getErrorMessage(error)
        throw errorMsg
      })
  }

  const withrawRequest = async () => {
    // console.log('withrawRequest', itemIFSC, data);
    // const loginName = JSON.parse(localStorage.getItem("user_data") || "{}").loginName
    // if (loginName && !!![9, 10].includes(loginName?.split("_")?.[1]?.length)) {
    //   const user = await actions.requestProfile()
    //   if (!user?.mobile_verified) {
    //     toastr.error('Error', "Please confirm your number")
    //     setShake(true)
    //     setTimeout(() => setShake(false), 600)
    //     return
    //   }
    // }
    if (!isRulesAccept && isEnoughMoney && !digits && isNamesMatch) {
      // console.log(
      //   'rules accepted',
      //   isRulesAccept,
      //   'amount',
      //   withdrawalAmount,
      //   'IFSC',
      //   withdrawalIFSC,
      //   'accNumber',
      //   withdrawalAccNumber,
      //   'Accname',
      //   withdrawalAccName
      // )
      setSubmitBtnDisabled(true)

      if (itemIFSC?._id) {
        requestPayment(itemIFSC?._id)
      } else {
        const userData = localStorage.getItem(USER_DATA)
        const userDetail = JSON.parse(userData)

        let payload = {
          user_id: userDetail?._id,
          method_id: parentMethodId,
          bank_holder_name: data.accountName.trim(),
          others: data.acNo,
        }

        if (depositMethod.category === 'BANK') {
          payload = {
            user_id: userDetail?._id,
            method_id: parentMethodId,
            bank_holder_name: data.accountName.trim(),
            bank_name: data.bankName.trim(),
            ifsc_code: data.IFSC,
            account_no: data.acNo,
          }
        }

        return await axios
          .post(BASE_DEV_URL + 'wallet/createPaymentMethod', payload, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            if (response.data.status) {
              requestPayment(response.data?.data[0]?._id)
            } else {
              toastr.info('', response.data?.msg)
              setSubmitBtnDisabled(false)
            }
          })
          .catch((e) => {
            // const errorMsg = getErrorMessage(e)
            // throw errorMsg
          })
      }
    }
  }

  useEffect(() => {
    if (needWait) {
      setTimeout(() => {
        setInputesCustom(false)
        setwithdrowalAmount(null)
        setWithdrawalIFSC('')
        setWithdrawalAccName('')
        setWithdrawalBankName('')
        setisNamesMatch(false)
        setWithdrawalAccNumber(null)
        setConfirmAccNumber(null)
        setAccountNumber('')
        setNeedWait(false)
      })
    }
  }, [needWait])

  useEffect(() => {
    parentMethodId ? codeAcc() : parentMethod()
  }, [parentMethodId])

  const parentMethod = async () => {
    const userData = localStorage.getItem(USER_DATA)
    const userDetail = JSON.parse(userData)

    const payload = {
      domain_id: userDetail?.domain_id,
      type: 'WITHDRAW',
      user_id: userDetail?._id,
    }

    return await axios
      .post(BASE_DEV_URL + 'wallet/getPayementMethod', payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.data.status) {
          setAccountInfo(response.data.data)
          setParentMethodId(response.data.data[0]?._id)
          setDepositMethod(response.data.data[0])
        } else {
          toastr.info('', response.data?.msg)
          setParentMethodId(null)
          setPrevAccounts()
        }
      })
      .catch((e) => {
        // const errorMsg = getErrorMessage(e)
        // throw errorMsg
      })
  }

  const codeIFSC = async (e) => {
    // return await axios
    //   .get(BASE_DEV_URL + `/payments/userid/ifsc?ifsc=${e}`, {
    //     headers: {
    //       Authorization: `${token}`,
    //     },
    //   })
    //   .then((response) => setInfoIFSC(response.data.result))
    //   .catch((e) => {
    //     setInfoIFSC()
    //     setDigits('Invalid IFSC code')
    //     const errorMsg = getErrorMessage(e)
    //     throw errorMsg
    //   })
  }
  const [prevAccounts, setPrevAccounts] = useState()

  useEffect(() => {
    if (prevAccounts?.length >= 5) {
      setMoreThenFive(true)
    } else {
      setMoreThenFive(false)
    }
  }, [prevAccounts?.length])

  const onMoreThenFive = () => {
    setInputesCustom(false)
    setInputesFromHistory(true)
    toastr.error(
      'You have reached a limit of 5 withdrawal accounts. Kindly delete any one from the list to add another'
    )
  }

  useEffect(() => {
    const sameAccount = prevAccounts?.find(
      (item) =>
        item?.ifsc_code === withdrawalIFSC &&
        item?.account_no === withdrawalAccNumber
    )
    if (inputesCustom && sameAccount) {
      toastr.error('This account is already exists')
      setSubmitBtnDisabled(true)
    } else {
      setSubmitBtnDisabled(false)
    }
  }, [withdrawalIFSC, withdrawalAccNumber])

  useEffect(() => {
    setInputesCustom(false)
    setwithdrowalAmount(null)
    setWithdrawalIFSC('')
    setWithdrawalAccName('')
    setWithdrawalBankName('')
    setisNamesMatch(false)
    setWithdrawalAccNumber(null)
    setConfirmAccNumber(null)
    setAccountNumber('')
    setAccNoEroor('')
  }, [history.location.pathname])

  const codeAcc = async () => {
    setWithdrawAccount()
    setItemIFSC({})
    setInputesCustom(false)
    setwithdrowalAmount(null)
    setWithdrawalIFSC('')
    setWithdrawalAccName('')
    setWithdrawalBankName('')
    setisNamesMatch(false)
    setWithdrawalAccNumber(null)
    setConfirmAccNumber(null)
    setAccountNumber('')
    const userData = localStorage.getItem(USER_DATA)
    const userDetail = JSON.parse(userData)

    let paylodad = {
      method_id: parentMethodId,
      user_id: userDetail?._id,
    }

    return await axios
      .post(BASE_DEV_URL + 'wallet/getwalletBankDetail', paylodad, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.data.status) {
          setPrevAccounts(response.data.data)
        } else {
          toastr.info('', 'You have no accounts yet')
          setPrevAccounts()
        }
      })
      .catch((e) => {
        // const errorMsg = getErrorMessage(e)
        // throw errorMsg
      })
  }

  const detailetGateway = async (item) => {
    setParentMethodId(item?._id)
    setDepositMethod(item)
    codeAcc()
  }

  useEffect(() => {
    setTimeout(() => {
      if (itemIFSC?.IFSCCode?.length === 11) {
        codeIFSC(itemIFSC?.IFSCCode)
      }
    })
  }, [itemIFSC?.IFSCCode])

  useEffect(() => {
    if (prevAccounts) {
      setWithdrawalIFSC()
      setWithdrawalAccName()
      setWithdrawalBankName()
      setisNamesMatch(false)
      setWithdrawalAccNumber()
      setWithdrawalIFSC(itemIFSC?.IFSCCode)
      setWithdrawalAccName(itemIFSC?.accountName)
      setisNamesMatch(true)
      setWithdrawalAccNumber(itemIFSC?.accountNo)
    }
  }, [itemIFSC])

  const onValidationAmount = (e) => {
    e = e.replace(/[^\d]/g, '')
    setwithdrowalAmount(e)
    if (Number(balance?.balance) < e) {
      setMessage('You don`t have enough money on your balance')
      // setTimeout(() => {
      //   setMessage('')
      // }, 3000)
      // setIsEnoughMoney(false)
    } else if (e < 0) {
      // setwithdrowalAmount(e)
      setMessage("Amount can't be negative")
      setIsEnoughMoney(false)
    } else if (e < 1) {
      // setwithdrowalAmount(e)
      setMessage("Amount can't be empty")
      setIsEnoughMoney(false)
    } else if (e < 0) {
      // setwithdrowalAmount(e)
      setMessage("Amount can't be less than 1")
      setIsEnoughMoney(false)
    } else {
      // setwithdrowalAmount(e)
      setMessage('')
      setIsEnoughMoney(true)
    }
  }

  const messageNoBalance = () => {
    setMessage('You don`t have enough money on your balance')
    setTimeout(() => {
      setMessage('')
    }, 3000)
  }

  const onValidationIFSC = (e) => {
    setWithdrawalIFSC(e)
    const regex = /^[A-Za-z]{4}0[A-Za-z0-9]{6}$/
    const allZeros = /^[A-Za-z]{4}0000000$/
    if (!/^[A-Z]{4}/.test(e)) {
      setDigits('The first four characters must be alphabetic')
      setInfoIFSC()
    } else if (e[4] != 0) {
      setDigits('The fifth character must be 0')
      setInfoIFSC()
    } else if (!/^[A-Za-z0-9]{6}$/.test(e?.slice(5))) {
      setDigits('The last six characters must be numeric or alphabetic')
      setInfoIFSC()
    } else {
      setDigits('')
      setInfoIFSC()
    }
    if (e?.length > 11) {
      setDigits('IFSC should be 11 digits')
      setInfoIFSC()
    }
    if (regex.test(e) && allZeros.test(e)) {
      setDigits('IFSC code cannot consist solely of zeros')
      setInfoIFSC()
    }
    if (regex.test(e) && !allZeros.test(e)) {
      codeIFSC(e)
      setDigits('')
    }
  }

  const onNewValidationIFSC = (e) => {
    setWithdrawalIFSC(e)
    const regex = /^[A-Za-z]{4}0[A-Za-z0-9]{6}$/
    const allZeros = /^[A-Za-z]{4}0000000$/
    if (!/^[A-Z]{4}/.test(e)) {
      setDigits('The first four characters must be alphabetic')
      setInfoIFSC()
    } else if (e[4] != 0) {
      setDigits('The fifth character must be 0')
      setInfoIFSC()
    } else if (!/^[A-Za-z0-9]{6}$/.test(e?.slice(5))) {
      setDigits('The last six characters must be numeric or alphabetic')
      setInfoIFSC()
    } else {
      setDigits('')
      setInfoIFSC()
    }
    if (e?.length > 11) {
      setDigits('IFSC should be 11 digits')
      setInfoIFSC()
    }
    if (regex.test(e) && allZeros.test(e)) {
      setDigits('IFSC code cannot consist solely of zeros.')
      setInfoIFSC()
    }
    if (regex.test(e) && !allZeros.test(e)) {
      codeIFSC(e)
      setDigits('')
    }
  }

  const onAccNumber = (e) => {
    e = e.replace(/[^\d]/g, '')
    setWithdrawalAccNumber(e)
    if (e.length <= 5) {
      setAccNoEroor('Account number should be more than 5 digits')
    } else {
      setAccNoEroor('')
    }
  }

  const onAccName = (e) => {
    e = e.replace(/[^A-Za-z ]/g, '')
    if (e.length < 32) {
      setWithdrawalAccName(e)
      setNameMessage('')
    } else {
      setNameMessage('Account name should be less than 32 digits')
    }
  }

  const onBankName = (e) => {
    if (e.length < 32) {
      setWithdrawalBankName(e)
      setBankMessage('')
    } else {
      setBankMessage('Bank name should be less than 32 digits')
    }
  }

  const onNewAccName = (e) => {
    if (e.length < 32) {
      setWithdrawalAccName(e)
      setNameMessage('')
    } else {
      setNameMessage('Account name should be less than 32 digits')
    }
  }

  useEffect(() => {
    if (confirmAccNumber?.length && withdrawalAccNumber !== confirmAccNumber) {
      setAccountNumber('Account number should match')
    } else {
      setAccountNumber('')
    }
  }, [withdrawalAccNumber, confirmAccNumber])

  const onValidationAccnumber = (e) => {
    e = e.replace(/[^\d]/g, '')
    setConfirmAccNumber(e)
    if (e.length > 0 && e !== withdrawalAccNumber) {
      setAccountNumber('Account number should match')
      setisNamesMatch(false)
    } else {
      setAccountNumber('')
      setisNamesMatch(true)
    }
  }

  useEffect(() => {
    if (inputesCustom) {
      setWithdrawalAccNumber('')
      setWithdrawalAccName('')
      setWithdrawalBankName('')
      setWithdrawalIFSC()
      setisNamesMatch(false)
      setWithdrawAccount('')
      setItemIFSC({})
      setIsRulesAccept(false)
      setInfoIFSC()
      setDigits()
      setAccountNumber('')
      setAccNoEroor('')
      setConfirmAccNumber('')
    }
  }, [inputesCustom])

  useEffect(() => {
    if (inputesFromHistory) {
      setWithdrawalAccNumber('')
      setWithdrawalAccName('')
      setWithdrawalBankName('')
      setWithdrawalIFSC()
      setisNamesMatch(false)
      setWithdrawAccount('')
      setItemIFSC({})
      setIsRulesAccept(false)
      setInfoIFSC()
      setDigits()
      setAccountNumber('')
      setAccNoEroor('')
      setConfirmAccNumber('')
    }
  }, [inputesFromHistory])

  useEffect(() => {
    return () => {
      setInputesCustom(false)
      setInputesFromHistory(false)
    }
  }, [])

  const width =
    elementsParams && elementsParams?.clientWidth
      ? elementsParams?.clientWidth
      : window.innerWidth

  // Fields check
  let [withdrawalAmountCheck, setWithdrawalAmountCheck] = useState('')
  let [withdrawalIFSCCheck, setWithdrawalIFSCCheck] = useState('')
  let [withdrawalAccNumberCheck, setWithdrawalAccNumberCheck] = useState('')
  let [withdrawalAccNameCheck, setWithdrawalAccNameCheck] = useState('')
  let [confirmAccNumberCheck, setConfirmAccNumberCheck] = useState('')
  let [isRulesAcceptCheck, setIsRulesAcceptCheck] = useState('')
  let [withdrawalBankNameCheck, setWithdrawalBankNameCheck] = useState('')

  const fieldCheck = () => {
    !withdrawalAmount
      ? setWithdrawalAmountCheck(false)
      : setWithdrawalAmountCheck('')
    !withdrawalIFSC ? setWithdrawalIFSCCheck(false) : setWithdrawalIFSCCheck('')
    !withdrawalAccNumber
      ? setWithdrawalAccNumberCheck(false)
      : setWithdrawalAccNumberCheck('')
    !withdrawalAccName
      ? setWithdrawalAccNameCheck(false)
      : setWithdrawalAccNameCheck('')
    confirmAccNumber.length < 1
      ? setConfirmAccNumberCheck(false)
      : setConfirmAccNumberCheck('')
    !isRulesAccept ? setIsRulesAcceptCheck(false) : setIsRulesAcceptCheck('')
    !withdrawalBankName
      ? setWithdrawalBankNameCheck(false)
      : setWithdrawalBankNameCheck('')
  }
  // End Fields check

  return (
    <IonPage className={openPopUpHelp && 'scrollOff ion-page-invisible'}>
      {width < 1024 ? (
        <IonHeader>
          <Toolbar />
        </IonHeader>
      ) : (
        <div style={{ height: '135px' }}></div>
      )}
      {width < 1024 ? (
        <IonContent className="ChangePassword" fullscreen>
          <form action="">
            <div className="withdraw-screen">
              <div className="withdraw-desktop">
                <div className="withdraw-desktop__rightpage">
                  <div className="backgroundInputs">
                    <div className="withdraw-desktop__leftpage">
                      <h2 className="page-title">Withdraw funds</h2>
                      {/* <div
                  className="page-help"
                  onClick={() => setOpenPopUpHelp(true)}
                >
                  <span className="page-help__text">Help</span>
                  <img loading={"lazy"} className="page-help__img" src={QuestionImg} alt="" />
                </div> */}
                    </div>
                    <div className="page-subtitle-notes">
                      <p>
                        1. This form is for withdrawing the amount from the main
                        wallet only.
                      </p>
                      <p>
                        2. The bonus wallet amount cannot be withdrawn by this
                        form.
                      </p>
                      <p>
                        3. Do not put Withdraw request without betting with
                        deposit amount. Such activity will be identified as
                        Suspicious
                      </p>
                      <p>
                        4. If multiple users are using same withdraw account
                        then all the linked users will be blocked.
                      </p>
                      <p>5. Paytm account numbers always start with 91</p>
                    </div>
                  </div>
                  <div className="page-subtitlee subtitle-hidden">
                    Please fill in all required fields
                  </div>

                  <div className="payments__row">
                    {accountInfo
                      ? accountInfo
                          .sort((a, b) => a.index - b.index)
                          .map((item) => {
                            return (
                              <>
                                {item._id !== 'WHATSAPP' && (
                                  <div
                                    key={item._id}
                                    className={
                                      (item._id == 'WHATSAPP'
                                        ? 'payments__row-whatsapp__item'
                                        : 'payments__row-item') +
                                      (depositMethod?._id == item._id
                                        ? ' active'
                                        : '')
                                    }
                                    onClick={() => {
                                      detailetGateway(item)
                                      setIsRulesAccept(true)
                                    }}
                                  >
                                    <div className="payments__row-item-radio">
                                      {depositMethod?._id === item?._id ? (
                                        <div className="selectedRadio"></div>
                                      ) : (
                                        <div className="unSelectedRadio"></div>
                                      )}
                                    </div>
                                    <div className="payments__row-item-logo">
                                      <img
                                        className="payments__row-item-logo-img"
                                        src={`${BASE_DEV_URL}bank_logo/${item.image}`}
                                        alt={item.name}
                                      />
                                      {/* <span className="payments__row-item-logo-title">
                                          {item.name}
                                        </span> */}
                                    </div>
                                    {item && item?.promo && (
                                      <span className="payments__row-item-depositMethod-promo">
                                        {item?.promo}
                                      </span>
                                    )}
                                  </div>
                                )}
                              </>
                            )
                          })
                      : null}
                  </div>

                  <div className="buttons-flex">
                    {width >= 1024 ? (
                      <button
                        type="button"
                        onClick={() => {
                          setInputesCustom(true)
                          setInputesFromHistory(false)
                          setWithdrawAccount()
                        }}
                        className="pushable"
                      >
                        <span className="shadow"></span>
                        <span className="edge refreshBtn"></span>
                        <span className="front refreshBtn">
                          Use New Account
                        </span>
                      </button>
                    ) : (
                      <div
                        className="withdraw-account__newitem "
                        onClick={() => {
                          setInputesCustom(true)
                          setInputesFromHistory(false)
                          setWithdrawAccount()
                          setIsRulesAccept(true)
                        }}
                      >
                        <div className="withdraw-account__newitem-title">
                          Use New Account
                        </div>
                      </div>
                    )}
                    {width >= 1024 ? (
                      <button
                        type="button"
                        onClick={() => {
                          setInputesCustom(false)
                          setInputesFromHistory(true)
                          codeAcc()
                        }}
                        className="pushable"
                      >
                        <span className="shadow"></span>
                        <span className="edge refreshBtn"></span>
                        <span className="front refreshBtn">
                          Use Previous Account
                        </span>
                      </button>
                    ) : (
                      <div
                        className="withdraw-account__newitem"
                        onClick={() => {
                          setInputesCustom(false)
                          setInputesFromHistory(true)
                          codeAcc()
                        }}
                      >
                        <div className="withdraw-account__newitem-title">
                          Use Previous Account
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="withdraw-account">
                    {/* TODO: map items and dispay data from api if exists */}

                    <div className="withdraw-account__flex">
                      {inputesFromHistory && prevAccounts?.length > 0
                        ? prevAccounts.map((item) => {
                            return (
                              <label
                                htmlFor={item._id}
                                className={
                                  withdrawAccount === item._id
                                    ? 'withdraw-account__item-checked'
                                    : 'withdraw-account__item'
                                }
                                onClick={() => {
                                  setWithdrawAccount(item._id)
                                  setInputesFromHistory(true)
                                  setInputesCustom(false)
                                }}
                              >
                                <div
                                  className="withdraw-account__item-content"
                                  onClick={() => setItemIFSC(item)}
                                >
                                  <div>
                                    <div className="withdraw-account__item-content__account">
                                      Acc. Holder:{' '}
                                      {item.bank_holder_name?.length > 15
                                        ? item.bank_holder_name.slice(0, 15) +
                                          '...'
                                        : item.bank_holder_name}
                                    </div>
                                    {depositMethod?.category === 'BANK' ? (
                                      <>
                                        <div className="withdraw-account__item-content__accountNo">
                                          Account Number: {item.account_no}
                                        </div>
                                        <div className="withdraw-account__item-content__accountNo">
                                          IFSC Code: {item.ifsc_code}
                                        </div>
                                        <div className="withdraw-account__item-content__IFSC">
                                          Bank Name: {item.bank_name}
                                          <input
                                            className="check-account"
                                            type="radio"
                                            name="choosenaccount"
                                            checked={
                                              withdrawAccount === item._id
                                            }
                                            id={item._id}
                                          />
                                          <img
                                            loading={'lazy'}
                                            className="delete-account"
                                            src={deleteButton}
                                            alt="deleteButton"
                                            onClick={() =>
                                              withdrawAccount === item._id &&
                                              setOpenPopUpDelete(true)
                                            }
                                          />
                                        </div>
                                      </>
                                    ) : (
                                      <div className="withdraw-account__item-content__IFSC">
                                        Mobile Number: {item.others}
                                        <input
                                          className="check-account"
                                          type="radio"
                                          name="choosenaccount"
                                          checked={withdrawAccount === item._id}
                                          id={item._id}
                                        />
                                        <img
                                          loading={'lazy'}
                                          className="delete-account"
                                          src={deleteButton}
                                          alt="deleteButton"
                                          onClick={() =>
                                            withdrawAccount === item._id &&
                                            setOpenPopUpDelete(true)
                                          }
                                        />
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </label>
                            )
                          })
                        : null}
                    </div>
                  </div>

                  {/* PREVIOUS ACCOUNTS */}

                  {inputesFromHistory && itemIFSC && prevAccounts && (
                    <div className="withdraw-desktop__rightpage-inputcontent">
                      <div className="backgroundInputs">
                        <div className="withdraw-input-content">
                          <div className="page-subtitle">Amount</div>
                          <input
                            id="Amount"
                            className="withdraw-textarea"
                            type="text"
                            placeholder="Enter Amount"
                            value={withdrawalAmount}
                            onChange={(e) => onValidationAmount(e.target.value)}
                          />
                          <span className="withdraw-textarea__currency">
                            INR
                          </span>
                          <span className="withdraw-textarea__currencyy">
                            ₹
                          </span>
                          <div className="withdraw-amaount-buttons">
                            {width >= 1024 ? (
                              <>
                                <button
                                  type="button"
                                  onClick={() => {
                                    if (
                                      balance?.availBalance <
                                      +withdrawalAmount + 1000
                                    ) {
                                      messageNoBalance()
                                    } else {
                                      setMessage('')
                                      setIsEnoughMoney(true)
                                      setwithdrowalAmount(
                                        +withdrawalAmount + 1000
                                      )
                                    }
                                  }}
                                  className="pushable"
                                >
                                  <span className="shadow"></span>
                                  <span className="edge refreshBtn"></span>
                                  <span className="front refreshBtn">
                                    +1,000
                                  </span>
                                </button>

                                <button
                                  type="button"
                                  onClick={() => {
                                    if (
                                      balance?.availBalance <
                                      +withdrawalAmount + 5000
                                    ) {
                                      messageNoBalance()
                                    } else {
                                      setMessage('')
                                      setIsEnoughMoney(true)
                                      setwithdrowalAmount(
                                        +withdrawalAmount + 5000
                                      )
                                    }
                                  }}
                                  className="pushable"
                                >
                                  <span className="shadow"></span>
                                  <span className="edge refreshBtn"></span>
                                  <span className="front refreshBtn">
                                    +5,000
                                  </span>
                                </button>

                                <button
                                  type="button"
                                  onClick={() => {
                                    if (
                                      balance?.availBalance <
                                      +withdrawalAmount + 10000
                                    ) {
                                      messageNoBalance()
                                    } else {
                                      setMessage('')
                                      setIsEnoughMoney(true)
                                      setwithdrowalAmount(
                                        +withdrawalAmount + 10000
                                      )
                                    }
                                  }}
                                  className="pushable"
                                >
                                  <span className="shadow"></span>
                                  <span className="edge refreshBtn"></span>
                                  <span className="front refreshBtn">
                                    +10,000
                                  </span>
                                </button>
                              </>
                            ) : (
                              <>
                                <div
                                  className="withdraw-amaount-buttons__value"
                                  onClick={() => {
                                    if (
                                      balance?.availBalance <
                                      +withdrawalAmount + 1000
                                    ) {
                                      messageNoBalance()
                                    } else {
                                      setMessage('')
                                      setIsEnoughMoney(true)
                                      setwithdrowalAmount(
                                        +withdrawalAmount + 1000
                                      )
                                    }
                                  }}
                                >
                                  +1,000
                                </div>

                                <div
                                  className="withdraw-amaount-buttons__value"
                                  onClick={() => {
                                    if (
                                      balance?.availBalance <
                                      +withdrawalAmount + 5000
                                    ) {
                                      messageNoBalance()
                                    } else {
                                      setMessage('')
                                      setIsEnoughMoney(true)
                                      setwithdrowalAmount(
                                        +withdrawalAmount + 5000
                                      )
                                    }
                                  }}
                                >
                                  +5,000
                                </div>
                                <div
                                  className="withdraw-amaount-buttons__value"
                                  onClick={() => {
                                    if (
                                      balance?.availBalance <
                                      +withdrawalAmount + 10000
                                    ) {
                                      messageNoBalance()
                                    } else {
                                      setMessage('')
                                      setIsEnoughMoney(true)
                                      setwithdrowalAmount(
                                        +withdrawalAmount + 10000
                                      )
                                    }
                                  }}
                                >
                                  +10,000
                                </div>
                              </>
                            )}
                          </div>
                          <div className="validation-message">{message}</div>
                        </div>
                        {depositMethod?.category === 'BANK' && (
                          <div className="withdraw-input-content">
                            <div className="page-subtitle">IFSC Code</div>
                            <input
                              className="withdraw-textarea uppercaseIFSC"
                              type="text"
                              placeholder="eg. IFSC0000001"
                              // onChange={(e) => setWithdrawalIFSC(e.target.value)}
                              onChange={(e) =>
                                onValidationIFSC(e.target.value.toUpperCase())
                              }
                              // defaultValue={itemIFSC?.IFSCCode}
                              disabled={true}
                              value={itemIFSC?.ifsc_code}
                            />
                            <div className="validation-message">{digits}</div>
                            {infoIFSC ? (
                              <div className="withdraw-textarea__description">
                                {infoIFSC?.BANK}, {infoIFSC?.BRANCH},{' '}
                                {infoIFSC?.CITY}
                              </div>
                            ) : null}
                          </div>
                        )}
                        <div className="withdraw-input-content ">
                          <div className="page-subtitle">
                            {depositMethod?.category === 'BANK'
                              ? 'Account'
                              : 'Mobile'}{' '}
                            number
                          </div>
                          <input
                            id="account"
                            className={`withdraw-textarea ${
                              !showNumber && 'hidden-accnumber'
                            }`}
                            type={width < 1024 ? 'tel' : 'text'}
                            placeholder={`Enter ${
                              depositMethod?.category === 'BANK'
                                ? 'Accout'
                                : 'Mobole'
                            } Number`}
                            disabled={true}
                            onChange={(e) => onAccNumber(e.target.value)}
                            onInput={(e) =>
                              (e.target.value = e.target.value.slice(0, 18))
                            }
                            value={
                              itemIFSC?.account_no || itemIFSC?.others
                                ? depositMethod?.category === 'BANK'
                                  ? itemIFSC?.account_no
                                  : itemIFSC?.others
                                : withdrawalAccNumber
                            }
                          />
                          <img
                            className="eyeIconWithdrawal"
                            src={showNumber ? eye : eyeOff}
                            alt="eyeIcon"
                            onClick={() => setShowNumber(!showNumber)}
                          />
                          <div className="validation-message">{accNoEroor}</div>
                        </div>
                        {depositMethod?.category === 'BANK' && (
                          <div className="withdraw-input-content">
                            <div className="page-subtitle">
                              Confirm account number
                            </div>
                            <input
                              className={`withdraw-textarea ${
                                !showConfirmNo && 'hidden-accnumber'
                              }`}
                              type={width < 1024 ? 'tel' : 'text'}
                              placeholder="Enter Accout Number"
                              // disabled={true}
                              onChange={(e) =>
                                onValidationAccnumber(e.target.value)
                              }
                              onInput={(e) =>
                                (e.target.value = e.target.value.slice(0, 18))
                              }
                              value={
                                itemIFSC?.account_no
                                  ? itemIFSC?.account_no
                                  : confirmAccNumber
                              }
                            />
                            <img
                              className="eyeIconWithdrawal"
                              src={showConfirmNo ? eye : eyeOff}
                              alt="eyeIcon"
                              onClick={() => setShowConfirmNo(!showConfirmNo)}
                            />
                            <div className="validation-message">
                              {accountNumber}
                            </div>
                          </div>
                        )}
                        <div className="withdraw-input-content">
                          <div className="page-subtitle">Account name</div>
                          <input
                            className="withdraw-textarea"
                            type="text"
                            placeholder="Enter Accout Name"
                            onChange={(e) => onAccName(e.target.value)}
                            disabled={true}
                            value={
                              itemIFSC?.bank_holder_name
                                ? itemIFSC?.bank_holder_name
                                : withdrawalAccName
                            }
                            maxLength={32}
                          />
                          {/* <div className="validation-message">{nameMessage}</div> */}
                        </div>
                        {depositMethod?.category === 'BANK' && (
                          <div className="withdraw-input-content">
                            <div className="page-subtitle">Bank name</div>
                            <input
                              className="withdraw-textarea"
                              type="text"
                              placeholder="Enter Bank Name"
                              onChange={(e) => onBankName(e.target.value)}
                              disabled={true}
                              value={
                                itemIFSC?.bank_name
                                  ? itemIFSC?.bank_name
                                  : withdrawalBankName
                              }
                              maxLength={32}
                            />
                            {/* <div className="validation-message">{nameMessage}</div> */}
                          </div>
                        )}
                      </div>
                      {/* <Verification submit={ () => setOtpVerif(true)} shake={shake} home={false}/> */}
                      <div className="rulesAccept">
                        {/* <input
                      className="rulesAccept__checkbox"
                      type="checkbox"
                      id="confirmWithdraw"
                      checked={isRulesAccept}
                      onChange={() => setIsRulesAccept(!isRulesAccept)}
                    /> */}
                        <div
                          className={`rulesAccept__checkboxW ${
                            !isRulesAccept
                              ? 'rulesAccept__checkbox_checked'
                              : ''
                          }`}
                          id="confirmWithdraw"
                          onClick={() => setIsRulesAccept(!isRulesAccept)}
                        />
                        <label htmlFor="confirmWithdraw"></label>
                        <span className="rulesAccept__text">
                          I have read and agree with &nbsp;
                          <span
                            className="rulesAccept__text-underline"
                            onClick={() =>
                              history.push('/termsOfWithdrowalPayment')
                            }
                          >
                            the terms of payment and withdrawal policy.
                          </span>
                        </span>
                      </div>
                      {width >= 1024 ? (
                        <div className="submitBtnW">
                          <button
                            type="button"
                            onClick={() =>
                              !moreThenFive
                                ? withrawRequest()
                                : onMoreThenFive()
                            }
                            className={(() => {
                              const isBank = depositMethod.category === 'BANK'

                              const commonConditions =
                                !isRulesAccept &&
                                isEnoughMoney &&
                                !digits &&
                                withdrawalAccName &&
                                withdrawalAccNumber &&
                                withdrawalAmount

                              const bankConditions =
                                withdrawalIFSC?.length === 11 &&
                                isNamesMatch &&
                                withdrawalBankName &&
                                confirmAccNumber === withdrawalAccNumber

                              const isValid = isBank
                                ? commonConditions && bankConditions
                                : commonConditions

                              return isValid
                                ? 'submitValidBtn'
                                : 'withdrawalNotValidBtn'
                            })()}
                            disabled={submitBtnDisabled}
                          >
                            {isRulesAccept &&
                            isEnoughMoney &&
                            !digits &&
                            isNamesMatch &&
                            withdrawalAmount ? (
                              <>
                                <span className="shadow"></span>
                                <span className="edge"></span>
                                <span className="front">Submit</span>
                              </>
                            ) : (
                              <div className="withdraw-submitNew__text">
                                Submit
                              </div>
                            )}
                          </button>
                        </div>
                      ) : (
                        <button
                          className={
                            !isRulesAccept &&
                            isEnoughMoney &&
                            !digits &&
                            isNamesMatch &&
                            withdrawalAmount &&
                            !submitBtnDisabled
                              ? 'withdraw-submit'
                              : 'withdrawalNotValidBtn'
                          }
                          onClick={() =>
                            !moreThenFive ? withrawRequest() : onMoreThenFive()
                          }
                          type="button"
                          disabled={submitBtnDisabled}
                        >
                          <div className="withdraw-submit__text">Submit</div>
                        </button>
                      )}
                    </div>
                  )}

                  {/* CREATE NEW ACCOUNT INPUTS */}

                  {inputesCustom && (
                    <div className="withdraw-desktop__rightpage-inputcontent">
                      <div className="backgroundInputs">
                        <div className="withdraw-input-content">
                          <div className="page-subtitle">Amount</div>
                          <input
                            id="Amount"
                            className="withdraw-textarea"
                            type="text"
                            placeholder="Enter Amount"
                            value={withdrawalAmount}
                            onChange={(e) => onValidationAmount(e.target.value)}
                          />
                          <span className="withdraw-textarea__currency">
                            INR
                          </span>
                          <span className="withdraw-textarea__currencyy">
                            ₹
                          </span>
                          <div className="withdraw-amaount-buttons">
                            {width >= 1024 ? (
                              <>
                                <button
                                  type="button"
                                  onClick={() => {
                                    if (
                                      balance?.availBalance <
                                      +withdrawalAmount + 1000
                                    ) {
                                      messageNoBalance()
                                    } else {
                                      setMessage('')
                                      setIsEnoughMoney(true)
                                      setwithdrowalAmount(
                                        +withdrawalAmount + 1000
                                      )
                                    }
                                  }}
                                  className="pushable"
                                >
                                  <span className="shadow"></span>
                                  <span className="edge refreshBtn"></span>
                                  <span className="front refreshBtn">
                                    +1,000
                                  </span>
                                </button>

                                <button
                                  type="button"
                                  onClick={() => {
                                    if (
                                      balance?.availBalance <
                                      +withdrawalAmount + 5000
                                    ) {
                                      messageNoBalance()
                                    } else {
                                      setMessage('')
                                      setIsEnoughMoney(true)
                                      setwithdrowalAmount(
                                        +withdrawalAmount + 5000
                                      )
                                    }
                                  }}
                                  className="pushable"
                                >
                                  <span className="shadow"></span>
                                  <span className="edge refreshBtn"></span>
                                  <span className="front refreshBtn">
                                    +5,000
                                  </span>
                                </button>

                                <button
                                  type="button"
                                  onClick={() => {
                                    if (
                                      balance?.availBalance <
                                      +withdrawalAmount + 10000
                                    ) {
                                      messageNoBalance()
                                    } else {
                                      setMessage('')
                                      setIsEnoughMoney(true)
                                      setwithdrowalAmount(
                                        +withdrawalAmount + 10000
                                      )
                                    }
                                  }}
                                  className="pushable"
                                >
                                  <span className="shadow"></span>
                                  <span className="edge refreshBtn"></span>
                                  <span className="front refreshBtn">
                                    +10,000
                                  </span>
                                </button>
                              </>
                            ) : (
                              <>
                                <div
                                  className="withdraw-amaount-buttons__value"
                                  onClick={() => {
                                    if (
                                      balance?.availBalance <
                                      +withdrawalAmount + 1000
                                    ) {
                                      messageNoBalance()
                                    } else {
                                      setMessage('')
                                      setIsEnoughMoney(true)
                                      setwithdrowalAmount(
                                        +withdrawalAmount + 1000
                                      )
                                    }
                                  }}
                                >
                                  +1,000
                                </div>

                                <div
                                  className="withdraw-amaount-buttons__value"
                                  onClick={() => {
                                    if (
                                      balance?.availBalance <
                                      +withdrawalAmount + 5000
                                    ) {
                                      messageNoBalance()
                                    } else {
                                      setMessage('')
                                      setIsEnoughMoney(true)
                                      setwithdrowalAmount(
                                        +withdrawalAmount + 5000
                                      )
                                    }
                                  }}
                                >
                                  +5,000
                                </div>
                                <div
                                  className="withdraw-amaount-buttons__value"
                                  onClick={() => {
                                    if (
                                      balance?.availBalance <
                                      +withdrawalAmount + 10000
                                    ) {
                                      messageNoBalance()
                                    } else {
                                      setMessage('')
                                      setIsEnoughMoney(true)
                                      setwithdrowalAmount(
                                        +withdrawalAmount + 10000
                                      )
                                    }
                                  }}
                                >
                                  +10,000
                                </div>
                              </>
                            )}
                          </div>
                          <div className="validation-message">{message}</div>
                        </div>
                        {depositMethod?.category === 'BANK' && (
                          <div className="withdraw-input-content">
                            <div className="page-subtitle">IFSC Code</div>
                            <input
                              className="withdraw-textarea uppercaseIFSC"
                              type="text"
                              // onReset={() => setInputedIFSC()}
                              // onChange={(e) => setWithdrawalIFSC(e.target.value)}
                              onChange={(e) =>
                                onNewValidationIFSC(
                                  e.target.value.toUpperCase()
                                )
                              }
                              placeholder="eg. IFSC0000001"
                              // defaultValue={itemIFSC?.IFSCCode}
                              // value={itemIFSC?.IFSCCode ? itemIFSC?.IFSCCode : inputedIFSC}
                            />
                            <div className="validation-message">{digits}</div>
                            {infoIFSC ? (
                              <div className="withdraw-textarea__description">
                                {infoIFSC?.BANK}, {infoIFSC?.BRANCH},{' '}
                                {infoIFSC?.CITY}
                              </div>
                            ) : null}
                          </div>
                        )}
                        <div className="withdraw-input-content">
                          <div className="page-subtitle">
                            {depositMethod?.category === 'BANK'
                              ? 'Account'
                              : 'Mobile'}{' '}
                            number
                          </div>
                          <input
                            id="account"
                            className={`withdraw-textarea ${
                              !showNumber && 'hidden-accnumber'
                            }`}
                            type={width < 1024 ? 'tel' : 'text'}
                            placeholder={`Enter ${
                              depositMethod?.category === 'BANK'
                                ? 'Accout'
                                : 'Mobile'
                            } Number`}
                            onChange={(e) => onAccNumber(e.target.value)}
                            onInput={(e) =>
                              (e.target.value = e.target.value.slice(0, 18))
                            }
                            value={withdrawalAccNumber}

                            // defaultValue={itemIFSC?.accountNo}
                          />
                          <img
                            className="eyeIconWithdrawal"
                            src={showNumber ? eye : eyeOff}
                            alt="eyeIcon"
                            onClick={() => setShowNumber(!showNumber)}
                          />
                          <div className="validation-message">{accNoEroor}</div>
                        </div>
                        {depositMethod?.category === 'BANK' && (
                          <div className="withdraw-input-content">
                            <div className="page-subtitle">
                              Confirm account number
                            </div>
                            <input
                              className={`withdraw-textarea ${
                                !showConfirmNo && 'hidden-accnumber'
                              }`}
                              type={width < 1024 ? 'tel' : 'text'}
                              placeholder="Enter Accout Number"
                              // disabled={!confirmAccNumber?.length && !withdrawalAccNumber}
                              onChange={(e) =>
                                onValidationAccnumber(e.target.value)
                              }
                              onInput={(e) =>
                                (e.target.value = e.target.value.slice(0, 18))
                              }
                              value={confirmAccNumber}

                              // defaultValue={itemIFSC?.accountNo}
                            />
                            <img
                              className="eyeIconWithdrawal"
                              src={showConfirmNo ? eye : eyeOff}
                              alt="eyeIcon"
                              onClick={() => setShowConfirmNo(!showConfirmNo)}
                            />
                            <div className="validation-message">
                              {accountNumber}
                            </div>
                          </div>
                        )}
                        <div className="withdraw-input-content">
                          <div className="page-subtitle">Account name</div>
                          <input
                            className="withdraw-textarea"
                            type="text"
                            placeholder="Enter Accout Name"
                            onChange={(e) => onAccName(e.target.value)}
                            value={withdrawalAccName}
                            // defaultValue={itemIFSC?.accountName}
                            maxLength={32}
                          />
                          <div className="validation-message">
                            {nameMessage}
                          </div>
                        </div>
                        {depositMethod?.category === 'BANK' && (
                          <div className="withdraw-input-content">
                            <div className="page-subtitle">Bank name</div>
                            <input
                              className="withdraw-textarea"
                              type="text"
                              placeholder="Enter Bank Name"
                              onChange={(e) => onBankName(e.target.value)}
                              value={withdrawalBankName}
                              // defaultValue={itemIFSC?.accountName}
                              maxLength={32}
                            />
                            <div className="validation-message">
                              {bankMessage}
                            </div>
                          </div>
                        )}
                      </div>
                      {/* <Verification submit={ () => setOtpVerif(true)} shake={shake} home={false}/> */}
                      <div className="rulesAccept">
                        {/* <input
                      className="rulesAccept__checkbox"
                      type="checkbox"
                      id="confirmWithdraw"
                      checked={isRulesAccept}
                      onChange={() => setIsRulesAccept(!isRulesAccept)}
                    /> */}
                        <div
                          className={`rulesAccept__checkboxW ${
                            !isRulesAccept
                              ? 'rulesAccept__checkbox_checked'
                              : ''
                          }`}
                          id="confirmWithdraw"
                          onClick={() => setIsRulesAccept(!isRulesAccept)}
                        />
                        <label htmlFor="confirmWithdraw"></label>
                        <span className="rulesAccept__text">
                          I have read and agree with &nbsp;
                          <span
                            className="rulesAccept__text-underline"
                            onClick={() =>
                              history.push('/termsOfWithdrowalPayment')
                            }
                          >
                            the terms of payment and withdrawal policy.
                          </span>
                        </span>
                      </div>
                      {width >= 1024 ? (
                        <div className="submitBtnW">
                          <button
                            type="button"
                            onClick={() =>
                              !moreThenFive
                                ? withrawRequest()
                                : onMoreThenFive()
                            }
                            className={(() => {
                              const isBank = depositMethod.category === 'BANK'

                              const commonConditions =
                                !isRulesAccept &&
                                isEnoughMoney &&
                                !digits &&
                                withdrawalAccName &&
                                withdrawalAccNumber &&
                                withdrawalAmount &&
                                !submitBtnDisabled

                              const bankConditions =
                                withdrawalIFSC?.length === 11 &&
                                isNamesMatch &&
                                withdrawalBankName &&
                                confirmAccNumber === withdrawalAccNumber

                              const isValid = isBank
                                ? commonConditions && bankConditions
                                : commonConditions

                              return isValid
                                ? 'submitValidBtn'
                                : 'withdrawalNotValidBtn'
                            })()}
                            disabled={submitBtnDisabled}
                          >
                            {(() => {
                              const isBank = depositMethod.category === 'BANK'

                              const commonConditions =
                                !isRulesAccept &&
                                isEnoughMoney &&
                                !digits &&
                                withdrawalAccName &&
                                withdrawalAccNumber &&
                                withdrawalAmount

                              const bankConditions =
                                withdrawalIFSC?.length === 11 &&
                                isNamesMatch &&
                                withdrawalBankName &&
                                confirmAccNumber === withdrawalAccNumber

                              const isValid = isBank
                                ? commonConditions && bankConditions
                                : commonConditions

                              return isValid ? (
                                <>
                                  <span className="shadow"></span>
                                  <span className="edge"></span>
                                  <span className="front">Submit</span>
                                </>
                              ) : (
                                <div className="withdraw-submitNew__text">
                                  Submit
                                </div>
                              )
                            })()}
                          </button>
                        </div>
                      ) : (
                        <button
                          className={(() => {
                            const isBank = depositMethod.category === 'BANK'

                            const commonConditions =
                              !isRulesAccept &&
                              isEnoughMoney &&
                              !digits &&
                              withdrawalAccName &&
                              withdrawalAccNumber &&
                              withdrawalAmount &&
                              !submitBtnDisabled

                            const bankConditions =
                              withdrawalIFSC?.length === 11 &&
                              isNamesMatch &&
                              withdrawalBankName &&
                              confirmAccNumber === withdrawalAccNumber

                            const isValid = isBank
                              ? commonConditions && bankConditions
                              : commonConditions

                            return isValid
                              ? 'withdraw-submit'
                              : 'withdrawalNotValidBtn'
                          })()}
                          onClick={() =>
                            !moreThenFive ? withrawRequest() : onMoreThenFive()
                          }
                          type="button"
                          // disabled={submitBtnDisabled}
                        >
                          <div className="withdraw-submit__text">Submit</div>
                        </button>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </form>
          {/*{width < 1024 && <FooterDesktop />}*/}
        </IonContent>
      ) : (
        <div className="ChangePassword" fullscreen>
          <form action="">
            <div className="withdraw-screen">
              <div className="withdraw-desktop">
                <div className="withdraw-desktop__leftpage">
                  <div>
                    <h2 className="page-title">Withdraw funds</h2>
                    <div className="page-subtitle notes">
                      <p>
                        1. This form is for withdrawing the amount from the main
                        wallet only.
                      </p>
                      <p>
                        2. The bonus wallet amount cannot be withdrawn by this
                        form.
                      </p>
                      <p>
                        3. Do not put Withdraw request without betting with
                        deposit amount. Such activity may be identified as
                        Suspicious
                      </p>
                      <p>
                        4. If multiple users are using same withdraw account
                        then all the linked users will be blocked.
                      </p>
                      <p>5. Paytm account numbers always start with 91</p>
                    </div>
                  </div>
                  <div
                    className="page-help"
                    // onClick={() => setOpenPopUpHelp(true)}
                  >
                    <span className="page-help__text">Help</span>
                    <img
                      loading={'lazy'}
                      className="page-help__img"
                      src={QuestionImg}
                      alt=""
                    />
                  </div>
                </div>
                <div className="withdraw-desktop__rightpage">
                  <div className="page-subtitle subtitle-hidden">
                    Please fill in all required fields
                  </div>

                  <div className="payments__row">
                    {accountInfo
                      ? accountInfo
                          .sort((a, b) => a.index - b.index)
                          .map((item) => {
                            return (
                              <>
                                {item._id !== 'WHATSAPP' && (
                                  <div
                                    key={item._id}
                                    className={
                                      depositMethod?._id == item._id
                                        ? 'payments__row-item active'
                                        : ' payments__row-item'
                                    }
                                    onClick={() => detailetGateway(item)}
                                  >
                                    <div className="payments__row-item-radio">
                                      {depositMethod?._id === item?._id ? (
                                        <div className="selectedRadio"></div>
                                      ) : (
                                        <div className="unSelectedRadio"></div>
                                      )}
                                    </div>
                                    <div className="payments__row-item-logo">
                                      <img
                                        className="payments__row-item-logo-img"
                                        src={`${BASE_DEV_URL}bank_logo/${item.image}`}
                                        alt={item.name}
                                      />
                                      {/* <span className="payments__row-item-logo-title">
                                        {item.name}
                                      </span> */}
                                    </div>
                                    {item && item?.promo && (
                                      <span className="payments__row-item-depositMethod-promo">
                                        {item?.promo}
                                      </span>
                                    )}
                                  </div>
                                )}
                              </>
                            )
                          })
                      : null}
                  </div>

                  <div className="buttons-flex">
                    {width >= 1024 ? (
                      <button
                        type="button"
                        onClick={() => {
                          setInputesCustom(true)
                          setInputesFromHistory(false)
                          setWithdrawAccount()
                        }}
                        className="pushable"
                      >
                        <span className="shadow"></span>
                        <span className="edge refreshBtn"></span>
                        <span className="front refreshBtn">
                          Use New Account
                        </span>
                      </button>
                    ) : (
                      <div
                        className="withdraw-account__newitem "
                        onClick={() => {
                          setInputesCustom(true)
                          setInputesFromHistory(false)
                          setWithdrawAccount()
                        }}
                      >
                        <div className="withdraw-account__newitem-title">
                          Use New Account
                        </div>
                      </div>
                    )}
                    {width >= 1024 ? (
                      <button
                        type="button"
                        onClick={() => {
                          setInputesCustom(false)
                          setInputesFromHistory(true)
                          codeAcc()
                        }}
                        className="pushable"
                      >
                        <span className="shadow"></span>
                        <span className="edge refreshBtn"></span>
                        <span className="front refreshBtn">
                          Use Previous Account
                        </span>
                      </button>
                    ) : (
                      <div
                        className="withdraw-account__newitem"
                        onClick={() => {
                          setInputesCustom(false)
                          setInputesFromHistory(true)
                          codeAcc()
                        }}
                      >
                        <div className="withdraw-account__newitem-title">
                          Use Previous Account
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="withdraw-account">
                    <div className="withdraw-account__flex">
                      {inputesFromHistory && prevAccounts?.length > 0
                        ? prevAccounts.map((item) => {
                            return (
                              <label
                                htmlFor={item._id}
                                className={
                                  withdrawAccount === item._id
                                    ? 'withdraw-account__item-checked'
                                    : 'withdraw-account__item'
                                }
                                onClick={() => {
                                  setWithdrawAccount(item._id)
                                  setInputesFromHistory(true)
                                  setInputesCustom(false)
                                }}
                              >
                                <div
                                  className="withdraw-account__item-content"
                                  onClick={() => setItemIFSC(item)}
                                >
                                  <div>
                                    <div className="withdraw-account__item-content__account">
                                      Acc. Holder:{' '}
                                      {item.bank_holder_name?.length > 15
                                        ? item.bank_holder_name.slice(0, 15) +
                                          '...'
                                        : item.bank_holder_name}
                                    </div>
                                    {depositMethod.category === 'BANK' ? (
                                      <>
                                        <div className="withdraw-account__item-content__accountNo">
                                          Account Number: {item.account_no}
                                        </div>
                                        <div className="withdraw-account__item-content__accountNo">
                                          IFSC Code: {item.ifsc_code}
                                        </div>
                                        <div className="withdraw-account__item-content__IFSC">
                                          Bank Name: {item.bank_name}
                                          <input
                                            className="check-account"
                                            type="radio"
                                            name="choosenaccount"
                                            checked={
                                              withdrawAccount === item._id
                                            }
                                            id={item._id}
                                          />
                                          <img
                                            loading={'lazy'}
                                            className="delete-account"
                                            src={deleteButton}
                                            alt=""
                                            onClick={() =>
                                              withdrawAccount === item._id &&
                                              setOpenPopUpDelete(true)
                                            }
                                          />
                                        </div>
                                      </>
                                    ) : (
                                      <div className="withdraw-account__item-content__IFSC">
                                        Mobile Number: {item.others}
                                        <input
                                          className="check-account"
                                          type="radio"
                                          name="choosenaccount"
                                          checked={withdrawAccount === item._id}
                                          id={item._id}
                                        />
                                        <img
                                          loading={'lazy'}
                                          className="delete-account"
                                          src={deleteButton}
                                          alt=""
                                          onClick={() =>
                                            withdrawAccount === item._id &&
                                            setOpenPopUpDelete(true)
                                          }
                                        />
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </label>
                            )
                          })
                        : null}
                    </div>
                  </div>

                  {/* PREVIOUS ACCOUNTS */}

                  {inputesFromHistory && itemIFSC && prevAccounts && (
                    <div className="withdraw-desktop__rightpage-inputcontent">
                      <div className="withdraw-input-content ">
                        <div
                          className={`page-subtitle ${
                            !withdrawalAmount && withdrawalAmountCheck === false
                              ? 'withdraw__empty-input'
                              : ''
                          }`}
                        >
                          <span>Amount</span>
                          {!withdrawalAmount &&
                          withdrawalAmountCheck === false ? (
                            <span className={`input-error-message`}>
                              Please fill required fields
                            </span>
                          ) : (
                            ''
                          )}
                        </div>
                        <input
                          id="Amount"
                          className="withdraw-textarea"
                          type="text"
                          placeholder="Enter Amount"
                          value={withdrawalAmount}
                          onChange={(e) => onValidationAmount(e.target.value)}
                        />
                        <span className="withdraw-textarea__currency">INR</span>
                        <span className="withdraw-textarea__currencyy">₹</span>
                        <div className="withdraw-amaount-buttons">
                          {width >= 1024 ? (
                            <>
                              <button
                                type="button"
                                onClick={() => {
                                  if (
                                    balance?.availBalance <
                                    +withdrawalAmount + 1000
                                  ) {
                                    messageNoBalance()
                                  } else {
                                    setMessage('')
                                    setIsEnoughMoney(true)
                                    setwithdrowalAmount(
                                      +withdrawalAmount + 1000
                                    )
                                  }
                                }}
                                className="pushable"
                              >
                                <span className="shadow"></span>
                                <span className="edge refreshBtn"></span>
                                <span className="front refreshBtn">+1,000</span>
                              </button>

                              <button
                                type="button"
                                onClick={() => {
                                  if (
                                    balance?.availBalance <
                                    +withdrawalAmount + 5000
                                  ) {
                                    messageNoBalance()
                                  } else {
                                    setMessage('')
                                    setIsEnoughMoney(true)
                                    setwithdrowalAmount(
                                      +withdrawalAmount + 5000
                                    )
                                  }
                                }}
                                className="pushable"
                              >
                                <span className="shadow"></span>
                                <span className="edge refreshBtn"></span>
                                <span className="front refreshBtn">+5,000</span>
                              </button>

                              <button
                                type="button"
                                onClick={() => {
                                  if (
                                    balance?.availBalance <
                                    +withdrawalAmount + 10000
                                  ) {
                                    messageNoBalance()
                                  } else {
                                    setMessage('')
                                    setIsEnoughMoney(true)
                                    setwithdrowalAmount(
                                      +withdrawalAmount + 10000
                                    )
                                  }
                                }}
                                className="pushable"
                              >
                                <span className="shadow"></span>
                                <span className="edge refreshBtn"></span>
                                <span className="front refreshBtn">
                                  +10,000
                                </span>
                              </button>
                            </>
                          ) : (
                            <>
                              <div
                                className="withdraw-amaount-buttons__value"
                                onClick={() => {
                                  if (
                                    balance?.availBalance <
                                    +withdrawalAmount + 1000
                                  ) {
                                    messageNoBalance()
                                  } else {
                                    setMessage('')
                                    setIsEnoughMoney(true)
                                    setwithdrowalAmount(
                                      +withdrawalAmount + 1000
                                    )
                                  }
                                }}
                              >
                                +1,000
                              </div>

                              <div
                                className="withdraw-amaount-buttons__value"
                                onClick={() => {
                                  if (
                                    balance?.availBalance <
                                    +withdrawalAmount + 5000
                                  ) {
                                    messageNoBalance()
                                  } else {
                                    setMessage('')
                                    setIsEnoughMoney(true)
                                    setwithdrowalAmount(
                                      +withdrawalAmount + 5000
                                    )
                                  }
                                }}
                              >
                                +5,000
                              </div>
                              <div
                                className="withdraw-amaount-buttons__value"
                                onClick={() => {
                                  if (
                                    balance?.availBalance <
                                    +withdrawalAmount + 10000
                                  ) {
                                    messageNoBalance()
                                  } else {
                                    setMessage('')
                                    setIsEnoughMoney(true)
                                    setwithdrowalAmount(
                                      +withdrawalAmount + 10000
                                    )
                                  }
                                }}
                              >
                                +10,000
                              </div>
                            </>
                          )}
                        </div>
                        <div className="validation-message">{message}</div>
                      </div>
                      {depositMethod.category === 'BANK' && (
                        <div className="withdraw-input-content">
                          <div className="page-subtitle">IFSC Code</div>
                          <input
                            className="withdraw-textarea uppercaseIFSC"
                            type="text"
                            // onChange={(e) => setWithdrawalIFSC(e.target.value)}
                            onChange={(e) =>
                              onValidationIFSC(e.target.value.toUpperCase())
                            }
                            // defaultValue={itemIFSC?.IFSCCode}
                            disabled={true}
                            value={itemIFSC?.ifsc_code}
                          />
                          <div className="validation-message">{digits}</div>
                          {infoIFSC ? (
                            <div className="withdraw-textarea__description">
                              {infoIFSC?.BANK}, {infoIFSC?.BRANCH},{' '}
                              {infoIFSC?.CITY}
                            </div>
                          ) : null}
                        </div>
                      )}
                      <div className="withdraw-input-content account-number-input-wrapper">
                        <div className="page-subtitle">
                          {depositMethod.category === 'BANK'
                            ? 'Account'
                            : 'Mobile'}{' '}
                          number
                        </div>
                        <input
                          id="account"
                          className={`withdraw-textarea ${
                            !showNumber && 'hidden-accnumber'
                          }`}
                          type={width < 1024 ? 'tel' : 'text'}
                          placeholder={`Enter ${
                            depositMethod.category === 'BANK'
                              ? 'Account'
                              : 'Mobile'
                          } Number`}
                          disabled={true}
                          onChange={(e) => onAccNumber(e.target.value)}
                          onInput={(e) =>
                            (e.target.value = e.target.value.slice(0, 18))
                          }
                          value={
                            itemIFSC?.account_no || itemIFSC?.others
                              ? depositMethod.category === 'BANK'
                                ? itemIFSC?.account_no
                                : itemIFSC?.others
                              : withdrawalAccNumber
                          }
                        />
                        <img
                          className="eyeIconWithdrawal"
                          src={showNumber ? eye : eyeOff}
                          alt="eyeIcon"
                          onClick={() => setShowNumber(!showNumber)}
                        />
                        <div className="validation-message">{accNoEroor}</div>
                      </div>
                      {depositMethod.category === 'BANK' && (
                        <div className="withdraw-input-content">
                          <div className="page-subtitle">
                            Confirm account number
                          </div>
                          <input
                            className={`withdraw-textarea ${
                              !showConfirmNo && 'hidden-accnumber'
                            }`}
                            type={width < 1024 ? 'tel' : 'text'}
                            placeholder="Enter Accout Number"
                            // disabled={true}
                            onChange={(e) =>
                              onValidationAccnumber(e.target.value)
                            }
                            onInput={(e) =>
                              (e.target.value = e.target.value.slice(0, 18))
                            }
                            value={
                              itemIFSC?.account_no
                                ? itemIFSC?.account_no
                                : confirmAccNumber
                            }
                          />
                          <img
                            className="eyeIconWithdrawal"
                            src={showConfirmNo ? eye : eyeOff}
                            alt="eyeIcon"
                            onClick={() => setShowConfirmNo(!showConfirmNo)}
                          />
                          <div className="validation-message">
                            {accountNumber}
                          </div>
                        </div>
                      )}
                      <div className="withdraw-input-content">
                        <div className="page-subtitle">Account name</div>
                        <input
                          className="withdraw-textarea"
                          type="text"
                          placeholder="Enter Accout Name"
                          onChange={(e) => onAccName(e.target.value)}
                          disabled={true}
                          value={
                            itemIFSC?.bank_holder_name
                              ? itemIFSC?.bank_holder_name
                              : withdrawalAccName
                          }
                          maxLength={32}
                        />
                        <div className="validation-message">{nameMessage}</div>
                      </div>
                      {depositMethod.category === 'BANK' && (
                        <div className="withdraw-input-content">
                          <div className="page-subtitle">Bank name</div>
                          <input
                            className="withdraw-textarea"
                            type="text"
                            placeholder="Enter Bank Name"
                            onChange={(e) => onAccName(e.target.value)}
                            disabled={true}
                            value={
                              itemIFSC?.bank_name
                                ? itemIFSC?.bank_name
                                : withdrawalBankName
                            }
                            maxLength={32}
                          />
                          <div className="validation-message">
                            {nameMessage}
                          </div>
                        </div>
                      )}
                      {/* <Verification submit={ () => setOtpVerif(true)} shake={shake} home={false}/> */}
                      <div
                        className={`rulesAccept ${
                          !isRulesAccept && isRulesAcceptCheck === false
                            ? 'deposit__empty-input'
                            : ''
                        }`}
                      >
                        {!isRulesAccept && isRulesAcceptCheck === false ? (
                          <span className={`input-error-message`}>
                            Please accept the rules.
                          </span>
                        ) : (
                          ''
                        )}
                        <div
                          className={`rulesAccept__checkboxW ${
                            !isRulesAccept
                              ? 'rulesAccept__checkbox_checked'
                              : ''
                          }`}
                          id="confirmWithdraw"
                          onClick={() => setIsRulesAccept(!isRulesAccept)}
                        />
                        <label htmlFor="confirmWithdraw"></label>
                        <span className="rulesAccept__text">
                          I have read and agree with &nbsp;
                          <span
                            className="rulesAccept__text-underline"
                            onClick={() =>
                              history.push('/termsOfWithdrowalPayment')
                            }
                          >
                            the terms of payment and withdrawal policy.
                          </span>
                        </span>
                      </div>
                      {width >= 1024 ? (
                        <div className="submitBtnW">
                          <button
                            type="button"
                            onClick={
                              !isRulesAccept &&
                              isEnoughMoney &&
                              !digits &&
                              isNamesMatch &&
                              withdrawalAmount
                                ? withrawRequest
                                : fieldCheck
                            }
                            className={` 
                        ${
                          !isRulesAccept &&
                          isEnoughMoney &&
                          !digits &&
                          isNamesMatch &&
                          withdrawalAmount
                            ? 'submitValidBtn'
                            : 'withdrawalNotValidBtn'
                        }`}
                            disabled={submitBtnDisabled}
                          >
                            {!isRulesAccept &&
                            isEnoughMoney &&
                            !digits &&
                            isNamesMatch &&
                            withdrawalAmount ? (
                              <>
                                <span className="shadow"></span>
                                <span className="edge"></span>
                                <span className="front">Submit</span>
                              </>
                            ) : (
                              <div className="withdraw-submitNew__text">
                                Submit
                              </div>
                            )}
                          </button>
                        </div>
                      ) : (
                        <button
                          className={
                            !isRulesAccept &&
                            isEnoughMoney &&
                            !digits &&
                            isNamesMatch &&
                            withdrawalAmount &&
                            !submitBtnDisabled
                              ? 'withdraw-submit'
                              : 'withdrawalNotValidBtn'
                          }
                          onClick={() =>
                            !moreThenFive ? withrawRequest() : onMoreThenFive()
                          }
                          type="button"
                          disabled={submitBtnDisabled}
                        >
                          <div className="withdraw-submit__text">Submit</div>
                        </button>
                      )}
                    </div>
                  )}

                  {/* CREATE NEW ACCOUNT INPUTS */}
                  {inputesCustom && (
                    <div className="withdraw-desktop__rightpage-inputcontent">
                      <div className="withdraw-input-content">
                        <div
                          className={`page-subtitle ${
                            !withdrawalAmount && withdrawalAmountCheck === false
                              ? 'withdraw__empty-input'
                              : ''
                          }`}
                        >
                          <span>Amount</span>
                          {!withdrawalAmount &&
                          withdrawalAmountCheck === false ? (
                            <span className={`input-error-message`}>
                              Please fill required fields
                            </span>
                          ) : (
                            ''
                          )}
                        </div>

                        <input
                          id="Amount"
                          className="withdraw-textarea"
                          type="text"
                          placeholder="Enter Amount"
                          value={withdrawalAmount}
                          onChange={(e) => onValidationAmount(e.target.value)}
                        />
                        <span className="withdraw-textarea__currency">INR</span>
                        <span className="withdraw-textarea__currencyy">₹</span>
                        <div className="withdraw-amaount-buttons">
                          {width >= 1024 ? (
                            <>
                              <button
                                type="button"
                                onClick={() => {
                                  if (
                                    balance?.availBalance <
                                    +withdrawalAmount + 1000
                                  ) {
                                    messageNoBalance()
                                  } else {
                                    setMessage('')
                                    setIsEnoughMoney(true)
                                    setwithdrowalAmount(
                                      +withdrawalAmount + 1000
                                    )
                                  }
                                }}
                                className="pushable"
                              >
                                <span className="shadow"></span>
                                <span className="edge refreshBtn"></span>
                                <span className="front refreshBtn">+1,000</span>
                              </button>

                              <button
                                type="button"
                                onClick={() => {
                                  if (
                                    balance?.availBalance <
                                    +withdrawalAmount + 5000
                                  ) {
                                    messageNoBalance()
                                  } else {
                                    setMessage('')
                                    setIsEnoughMoney(true)
                                    setwithdrowalAmount(
                                      +withdrawalAmount + 5000
                                    )
                                  }
                                }}
                                className="pushable"
                              >
                                <span className="shadow"></span>
                                <span className="edge refreshBtn"></span>
                                <span className="front refreshBtn">+5,000</span>
                              </button>

                              <button
                                type="button"
                                onClick={() => {
                                  if (
                                    balance?.availBalance <
                                    +withdrawalAmount + 10000
                                  ) {
                                    messageNoBalance()
                                  } else {
                                    setMessage('')
                                    setIsEnoughMoney(true)
                                    setwithdrowalAmount(
                                      +withdrawalAmount + 10000
                                    )
                                  }
                                }}
                                className="pushable"
                              >
                                <span className="shadow"></span>
                                <span className="edge refreshBtn"></span>
                                <span className="front refreshBtn">
                                  +10,000
                                </span>
                              </button>
                            </>
                          ) : (
                            <>
                              <div
                                className="withdraw-amaount-buttons__value"
                                onClick={() => {
                                  if (
                                    balance?.availBalance <
                                    +withdrawalAmount + 1000
                                  ) {
                                    messageNoBalance()
                                  } else {
                                    setMessage('')
                                    setIsEnoughMoney(true)
                                    setwithdrowalAmount(
                                      +withdrawalAmount + 1000
                                    )
                                  }
                                }}
                              >
                                +1,000
                              </div>

                              <div
                                className="withdraw-amaount-buttons__value"
                                onClick={() => {
                                  if (
                                    balance?.availBalance <
                                    +withdrawalAmount + 5000
                                  ) {
                                    messageNoBalance()
                                  } else {
                                    setMessage('')
                                    setIsEnoughMoney(true)
                                    setwithdrowalAmount(
                                      +withdrawalAmount + 5000
                                    )
                                  }
                                }}
                              >
                                +5,000
                              </div>
                              <div
                                className="withdraw-amaount-buttons__value"
                                onClick={() => {
                                  if (
                                    balance?.availBalance <
                                    +withdrawalAmount + 10000
                                  ) {
                                    messageNoBalance()
                                  } else {
                                    setMessage('')
                                    setIsEnoughMoney(true)
                                    setwithdrowalAmount(
                                      +withdrawalAmount + 10000
                                    )
                                  }
                                }}
                              >
                                +10,000
                              </div>
                            </>
                          )}
                        </div>
                        <div className="validation-message">{message}</div>
                      </div>
                      {depositMethod.category === 'BANK' && (
                        <div className="withdraw-input-content">
                          <div
                            className={`page-subtitle ${
                              !withdrawalIFSC && withdrawalIFSCCheck === false
                                ? 'withdraw__empty-input'
                                : ''
                            }`}
                          >
                            <span>IFSC Code</span>
                            {!withdrawalIFSC &&
                            withdrawalIFSCCheck === false ? (
                              <span className={`input-error-message`}>
                                Please fill required fields
                              </span>
                            ) : (
                              ''
                            )}
                          </div>
                          <input
                            className="withdraw-textarea uppercaseIFSC"
                            type="text"
                            placeholder="eg. IFSC0000001"
                            // onReset={() => setInputedIFSC()}
                            // onChange={(e) => setWithdrawalIFSC(e.target.value)}
                            onChange={(e) =>
                              onNewValidationIFSC(e.target.value.toUpperCase())
                            }
                            // defaultValue={itemIFSC?.IFSCCode}
                            // value={itemIFSC?.IFSCCode ? itemIFSC?.IFSCCode : inputedIFSC}
                          />
                          <div className="validation-message">{digits}</div>
                          {infoIFSC ? (
                            <div className="withdraw-textarea__description">
                              {infoIFSC?.BANK}, {infoIFSC?.BRANCH},{' '}
                              {infoIFSC?.CITY}
                            </div>
                          ) : null}
                        </div>
                      )}
                      <div className="withdraw-input-content account-number-input-wrapper">
                        <div
                          className={`page-subtitle ${
                            !withdrawalAccNumber &&
                            withdrawalAccNumberCheck === false
                              ? 'withdraw__empty-input'
                              : ''
                          }`}
                        >
                          <span>
                            {depositMethod.category === 'BANK'
                              ? 'Account'
                              : 'Mobile'}{' '}
                            number
                          </span>
                          {!withdrawalAccNumber &&
                          withdrawalAccNumberCheck === false ? (
                            <span className={`input-error-message`}>
                              Please fill required fields
                            </span>
                          ) : (
                            ''
                          )}
                        </div>
                        <input
                          id="account"
                          className={`withdraw-textarea ${
                            !showNumber && 'hidden-accnumber'
                          }`}
                          type={width < 1024 ? 'tel' : 'text'}
                          placeholder={`Enter ${
                            depositMethod.category === 'BANK'
                              ? 'Accout'
                              : 'Mobile'
                          } Number`}
                          onChange={(e) => onAccNumber(e.target.value)}
                          onInput={(e) =>
                            (e.target.value = e.target.value.slice(0, 18))
                          }
                          value={withdrawalAccNumber}

                          // defaultValue={itemIFSC?.accountNo}
                        />
                        <img
                          className="eyeIconWithdrawal"
                          src={showNumber ? eye : eyeOff}
                          alt="eyeIcon"
                          onClick={() => setShowNumber(!showNumber)}
                        />
                        <div className="validation-message">{accNoEroor}</div>
                      </div>
                      {depositMethod.category === 'BANK' && (
                        <div className="withdraw-input-content ">
                          <div
                            className={`page-subtitle ${
                              confirmAccNumber?.length < 1 &&
                              confirmAccNumberCheck === false
                                ? 'withdraw__empty-input'
                                : ''
                            }`}
                          >
                            <span>Confirm account number</span>
                            {confirmAccNumber?.length < 1 &&
                            confirmAccNumberCheck === false ? (
                              <span className={`input-error-message`}>
                                Please fill required fields
                              </span>
                            ) : (
                              ''
                            )}
                          </div>
                          <input
                            className={`withdraw-textarea ${
                              !showConfirmNo && 'hidden-accnumber'
                            }`}
                            type={width < 1024 ? 'tel' : 'text'}
                            placeholder="Enter Accout Number"
                            // disabled={!confirmAccNumber?.length && !withdrawalAccNumber}
                            onChange={(e) =>
                              onValidationAccnumber(e.target.value)
                            }
                            onInput={(e) =>
                              (e.target.value = e.target.value.slice(0, 18))
                            }
                            value={confirmAccNumber}
                            // defaultValue={itemIFSC?.accountNo}
                          />
                          <img
                            className="eyeIconWithdrawal"
                            src={showConfirmNo ? eye : eyeOff}
                            alt="eyeIcon"
                            onClick={() => setShowConfirmNo(!showConfirmNo)}
                          />
                          <div className="validation-message">
                            {accountNumber}
                          </div>
                        </div>
                      )}
                      <div className="withdraw-input-content">
                        <div
                          className={`page-subtitle ${
                            !withdrawalAccName &&
                            withdrawalAccNameCheck === false
                              ? 'withdraw__empty-input'
                              : ''
                          }`}
                        >
                          <span>Account name</span>
                          {!withdrawalAccName &&
                          withdrawalAccNameCheck === false ? (
                            <span className={`input-error-message`}>
                              Please fill required fields
                            </span>
                          ) : (
                            ''
                          )}
                        </div>
                        <input
                          className="withdraw-textarea"
                          type="text"
                          placeholder="Enter Accout Name"
                          onChange={(e) => onAccName(e.target.value)}
                          value={withdrawalAccName}
                          // defaultValue={itemIFSC?.accountName}
                          maxLength={32}
                        />
                        <div className="validation-message">{nameMessage}</div>
                      </div>
                      {depositMethod.category === 'BANK' && (
                        <div className="withdraw-input-content">
                          <div
                            className={`page-subtitle ${
                              !withdrawalBankName &&
                              withdrawalBankNameCheck === false
                                ? 'withdraw__empty-input'
                                : ''
                            }`}
                          >
                            <span>Bank name</span>
                            {!withdrawalBankName &&
                            withdrawalBankNameCheck === false ? (
                              <span className={`input-error-message`}>
                                Please fill required fields
                              </span>
                            ) : (
                              ''
                            )}
                          </div>
                          <input
                            className="withdraw-textarea"
                            type="text"
                            placeholder="Enter Bank Name"
                            onChange={(e) => onBankName(e.target.value)}
                            value={withdrawalBankName}
                            // defaultValue={itemIFSC?.accountName}
                            maxLength={32}
                          />
                          <div className="validation-message">
                            {bankMessage}
                          </div>
                        </div>
                      )}
                      {/* <Verification submit={ () => setOtpVerif(true)} shake={shake} home={false}/> */}
                      <div
                        className={`rulesAccept ${
                          !isRulesAccept && isRulesAcceptCheck === false
                            ? 'deposit__empty-input'
                            : ''
                        }`}
                      >
                        {!isRulesAccept && isRulesAcceptCheck === false ? (
                          <span className={`input-error-message`}>
                            Please accept the rules.
                          </span>
                        ) : (
                          ''
                        )}
                        {/* <input
                    className="rulesAccept__checkbox"
                    type="checkbox"
                    id="confirmWithdraw"
                    checked={isRulesAccept}
                    onChange={() => setIsRulesAccept(!isRulesAccept)}
                  /> */}
                        <div
                          className={`rulesAccept__checkboxW ${
                            !isRulesAccept
                              ? 'rulesAccept__checkbox_checked'
                              : ''
                          }`}
                          id="confirmWithdraw"
                          onClick={() => setIsRulesAccept(!isRulesAccept)}
                        />
                        <label htmlFor="confirmWithdraw"></label>
                        <span className="rulesAccept__text">
                          I have read and agree with &nbsp;
                          <span
                            className="rulesAccept__text-underline"
                            onClick={() =>
                              history.push('/termsOfWithdrowalPayment')
                            }
                          >
                            the terms of payment and withdrawal policy.
                          </span>
                        </span>
                      </div>
                      {width >= 1024 ? (
                        <div className="submitBtnW">
                          <button
                            type="button"
                            onClick={() => {
                              const isBank = depositMethod.category === 'BANK'

                              const commonConditions =
                                !isRulesAccept &&
                                isEnoughMoney &&
                                !digits &&
                                withdrawalAccName &&
                                withdrawalAccNumber &&
                                withdrawalAmount

                              const bankConditions =
                                withdrawalIFSC?.length === 11 &&
                                isNamesMatch &&
                                withdrawalBankName &&
                                confirmAccNumber === withdrawalAccNumber

                              if (
                                isBank
                                  ? commonConditions && bankConditions
                                  : commonConditions
                              ) {
                                withrawRequest()
                              } else {
                                fieldCheck()
                              }
                            }}
                            className={(() => {
                              const isBank = depositMethod.category === 'BANK'

                              const commonConditions =
                                !isRulesAccept &&
                                isEnoughMoney &&
                                !digits &&
                                withdrawalAccName &&
                                withdrawalAccNumber &&
                                withdrawalAmount

                              const bankConditions =
                                withdrawalIFSC?.length === 11 &&
                                isNamesMatch &&
                                withdrawalBankName &&
                                confirmAccNumber === withdrawalAccNumber

                              const isValid = isBank
                                ? commonConditions && bankConditions
                                : commonConditions

                              return isValid
                                ? 'submitValidBtn'
                                : 'withdrawalNotValidBtn'
                            })()}
                            disabled={submitBtnDisabled}
                          >
                            {(() => {
                              const isBank = depositMethod.category === 'BANK'

                              const commonConditions =
                                !isRulesAccept &&
                                isEnoughMoney &&
                                !digits &&
                                withdrawalAccName &&
                                withdrawalAccNumber &&
                                withdrawalAmount

                              const bankConditions =
                                withdrawalIFSC?.length === 11 &&
                                isNamesMatch &&
                                withdrawalBankName &&
                                confirmAccNumber === withdrawalAccNumber

                              const isValid = isBank
                                ? commonConditions && bankConditions
                                : commonConditions

                              return isValid ? (
                                <>
                                  <span className="shadow"></span>
                                  <span className="edge"></span>
                                  <span className="front">Submit</span>
                                </>
                              ) : (
                                <div className="withdraw-submitNew__text">
                                  Submit
                                </div>
                              )
                            })()}
                          </button>
                        </div>
                      ) : (
                        <button
                          className={
                            isRulesAccept &&
                            isEnoughMoney &&
                            withdrawalIFSC?.length === 11 &&
                            !digits &&
                            isNamesMatch &&
                            withdrawalAccName &&
                            withdrawalAmount &&
                            withdrawalBankName &&
                            withdrawalAccNumber &&
                            confirmAccNumber === withdrawalAccNumber &&
                            !submitBtnDisabled
                              ? 'withdraw-submit'
                              : 'withdrawalNotValidBtn'
                          }
                          onClick={() =>
                            !moreThenFive ? withrawRequest() : onMoreThenFive()
                          }
                          type="button"
                          disabled={submitBtnDisabled}
                        >
                          <div className="withdraw-submit__text">Submit</div>
                        </button>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </form>
          <FooterDesktop />
        </div>
      )}
      <PopUPDelete
        update={() => codeAcc()}
        data={withdrawAccount}
        open={openPopUpDelete}
        onDidDismiss={() => setOpenPopUpDelete(false)}
      />
      <PopUPWithdrawalHelp
        // data={data}
        // content={editPoUP}
        open={openPopUpHelp}
        onDidDismiss={() => setOpenPopUpHelp(false)}
      />
      <SuccessPopUp
        open={showSuccessPopUP}
        onDidDismiss={() => setShowSuccessPopUP(false)}
        amount={withdrawalAmount}
      />
    </IonPage>
  )
}

export default connectWithdrawal()(Withdrawal)

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { changeWalletStatus, requestBalance } from '../../store/balance/balance.thunks'
import { setBalanceInformationHeight } from '../../store/balanceInformationHeight/balanceInformationHeight.slice'
import { setIsDetailedBalanceOpen } from '../../store/isDetailedBalanceOpen/isDetailedBalanceOpen.slice'

export const connectBalanceInformation = (wrappedProps) => (component) => {
  function mapStateToProps(state) {
    return {
      balance: state.balance,
      auth: state.auth,
      user: state.user,
      isDetailedBalanceOpen: state.isDetailedBalanceOpen
    }
  }

  function mapDispatchToProps(dispatch) {
    const actions = {
      changeWalletStatus,
      setBalanceInformationHeight,
      setIsDetailedBalanceOpen,
      requestBalance,
    } 

    return { actions: bindActionCreators(actions, dispatch) }
  }

  return connect(mapStateToProps, mapDispatchToProps)(component)
}

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

export const connectLiveCasinoAndSlots = (wrappedProps) => (component) => {
  function mapStateToProps(state) {
    return {
      liveCasino: state.liveCasino,
      slotsGames: state.slotsGames,
      elementsParams : state.elementsParams,
      slotCasinoPopUp: state.slotCasinoPopUp
    }
  }

  function mapDispatchToProps(dispatch) {
    const actions = {
      
      
    }

    return { actions: bindActionCreators(actions, dispatch) }
  }

  return connect(mapStateToProps, mapDispatchToProps)(component)
}

import './BettingProfitAndLossItemList.scss'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import BettingProfitAndLoseItem from '../BettingProfitAndLossItem/BettingProfitAndLossItem'
import { tsToDate } from '../../helpers/date.helper'
import { getCurrencyFormat } from '../../helpers/getCurrencyFormat.helper'

const BettingProfitAndLossItemList = ({
  listItemsObject,
  timeSettings,
  setSelectedItemDetails,
}) => {
  //get window with
  const checkStateWidth = useSelector(
    (state) => state.elementsParams.clientWidth
  )
  const width = checkStateWidth ? checkStateWidth : window.innerWidth
  //end get window with

  const [totalPL, setTotalPL] = useState('0')
  const [dataList, setDataList] = useState([])

  const calculateTotalPL = (data) => {
    let total = 0
    data.forEach((d) => {
      total += d.net_pl
    })
    setTotalPL(total.toFixed(2))
  }

  const getValueColor = (value) => {
    return value == 0
      ? 'pnl-item-value--black'
      : value > 0
      ? 'pnl-item-value--green'
      : 'pnl-item-value--red'
  }

  useEffect(() => {
    setDataList(listItemsObject.data)
    calculateTotalPL(listItemsObject.data)
  }, [listItemsObject])

  if (width < 1024) {
    return (
      <div className="betting-profit-and-lose-item-list">
        <div className="betting-profit-and-lose-item-list__header">
          <span className="betting-profit-and-lose-item-list__header-date">
            {listItemsObject.dateString}
          </span>
          <span className="betting-profit-and-lose-item-list__header-total-pl">
            <span className="betting-profit-and-lose-item-list__header-total-pl-title">
              P&L:
            </span>
            <span
              className={`betting-profit-and-lose-item-list__header-total-pl-value ${getValueColor(
                totalPL
              )}`}
            >
              {getCurrencyFormat(totalPL)}
            </span>
          </span>
        </div>
        <div className="betting-profit-and-lose-item-list__content">
          {dataList.map((d) => {
            return <BettingProfitAndLoseItem key={d.match_id} data={d} />
          })}
        </div>
      </div>
    )
  }

  return (
    <div className="betting-profit-and-lose-item-list">
      <div className="betting-profit-and-lose-item-list__header">
        {/*<span className="betting-profit-and-lose-item-list__header-date">{listItemsObject.dateString}</span>*/}
        <span className="betting-profit-and-lose-item-list__header-date">
          {tsToDate(listItemsObject?.time, timeSettings?.timeZoneName)
            ?.split(' ')[0]
            .split('/')
            .map((dateItem) =>
              dateItem.length === 1 ? '0' + dateItem : dateItem
            )
            .join('/')}
        </span>
        {/*<span className="betting-profit-and-lose-item-list__header-total-pl">*/}
        {/*    <span className="betting-profit-and-lose-item-list__header-total-pl-title">P&L:</span>*/}
        {/*    <span className={`betting-profit-and-lose-item-list__header-total-pl-value ${getValueColor(totalPL)}`}>{totalPL}</span>*/}
        {/*</span>*/}
      </div>
      <div className="betting-profit-and-lose-item-list__content">
        {dataList.map((d) => {
          return (
            <ul className="betting-pnl-item">
              <li>
                <span
                  className="betting-pnl-item__cell__value betting-pnl-item__title"
                  onClick={() => setSelectedItemDetails(d)}
                >
                  {`${d.event_name} - ${d.match_name}`}
                </span>
              </li>
              <li>
                <span className="betting-pnl-item__cell__value start-time__value">
                  {d.match_date ? new Intl.DateTimeFormat('en-GB', {
                    dateStyle: 'short',
                    timeStyle: 'medium',
                  }).format(new Date(d.match_date)) : '--'}
                </span>
              </li>
              <li>
                <span className="betting-pnl-item__cell__value settled-time__value">
                  {new Intl.DateTimeFormat('en-GB', {
                    dateStyle: 'short',
                    timeStyle: 'medium',
                  }).format(new Date(d.result_date))}
                </span>
              </li>
              <li>
                <span
                  className={`betting-pnl-item__cell__value comm__value ${getValueColor(
                    -1 * 0
                  )}`}
                >
                  {(-1 * 0).toFixed(2)}
                </span>
              </li>
              <li>
                <span
                  className={`betting-pnl-item__cell__value net-win__value ${getValueColor(
                    d.net_pl
                  )}`}
                >
                  {getCurrencyFormat(d.net_pl)}
                </span>
              </li>
            </ul>
            // <div>{d.match_id}</div>
            // <BettingProfitAndLoseItem
            //   key={d.match_id}
            //   data={d}
            //   setSelectedItemDetails={setSelectedItemDetails}
            // />
          )
        })}
      </div>
    </div>
  )
}

export default BettingProfitAndLossItemList
